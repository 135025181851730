import { ReactComponent as LeftRightArrow } from "./LeftRightArrow.svg";

const LeftRightArrowIcon = () => {
  return (
    <>
      <LeftRightArrow />
    </>
  );
};

export default LeftRightArrowIcon;
