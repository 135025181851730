import { useEffect, useState } from "react";

interface BreakpointsProps {

    sm?: React.ReactNode;
    md?: React.ReactNode;
    xl?: React.ReactNode;
    xxl?: React.ReactNode;
}

export enum BreakpointsSize {
    sm = 480,
    md = 600,
    xl = 1100,
    xxl = 1400,
}

export type BreakpointsPrefix = "sm" | "md" | "xl" | "xxl";

export type BreakpointsFunction = (
    breakpoint: BreakpointsPrefix,
    size: BreakpointsSize
) => void;

export type BreakpointsSubscribe = (
    callback: (breakpoint: BreakpointsPrefix, size: BreakpointsSize) => void,
    exec?: boolean
) => VoidFunction;

export type BreakpointsUnSubscribe = (callback: BreakpointsFunction) => void;

interface BreakpointsState {
    size: BreakpointsSize;
}

export const Breakpoints: {
    ({ sm, md, xl, xxl }: BreakpointsProps): JSX.Element;
    Prefix: any;
    Subscribe: any;
} = ({ sm, md, xl, xxl }: BreakpointsProps): JSX.Element => {
    const [size, setSize] = useState<
        BreakpointsSize | BreakpointsPrefix | string
    >(BreakpointsSize.sm);

    const updator: BreakpointsFunction = (
        breakpoint: BreakpointsPrefix,
        size: BreakpointsSize | undefined
    ) => {
        if (size) {
            setSize(size);
        }
    };

    useEffect(() => {
        let unsubscribe: VoidFunction | null = null;

        if (Breakpoints.Prefix) {
            setSize(BreakpointsSize[Breakpoints.Prefix]);
        }

        unsubscribe = Breakpoints.Subscribe(updator);

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [updator]);

    if (xxl && size >= BreakpointsSize.xxl) {
        return <>{xxl}</>;
    }
    if (xl && size >= BreakpointsSize.xl) {
        return <>{xl}</>;
    }
    if (md && size >= BreakpointsSize.md) {
        return <>{md}</>;
    }
    if (sm && size >= BreakpointsSize.sm) {
        return <>{sm}</>;
    }
    return <>{sm}</>;
};

Breakpoints.Prefix = undefined;
const BreakpointsData: Array<BreakpointsFunction> = new Array();

Breakpoints.Subscribe = (callback: any, exec?: boolean) => {
    BreakpointsData.push(callback);

    if (exec && Breakpoints.Prefix) {
        callback(Breakpoints.Prefix, BreakpointsSize[Breakpoints.Prefix]);
    }

    return () => {
        const idx = BreakpointsData.findIndex(
            (_callback) => callback === _callback
        );

        if (idx > -1) {
            BreakpointsData.splice(idx, 1);
        }
    };
};

const onResize = () => {
    const updator = (prefix: BreakpointsPrefix) => {
        if (prefix !== Breakpoints.Prefix) {
            Breakpoints.Prefix = prefix;
            BreakpointsData.forEach((callback) =>
                callback(prefix, BreakpointsSize[prefix])
            );
        }
    };

    if (window.innerWidth > BreakpointsSize.xxl) {
        updator("xxl");
    } else if (window.innerWidth > BreakpointsSize.xl) {
        updator("xl");
    } else if (window.innerWidth > BreakpointsSize.md) {
        updator("md");
    } else if (window.innerWidth > BreakpointsSize.sm) {
        updator("sm");
    } else if (
        window.innerWidth < BreakpointsSize.sm
    ) {
        updator("sm");
    }
};

window.addEventListener("resize", onResize);
onResize();
