import { ReactComponent as SpacePlanet7 } from "./spacePlanet7.svg";

const Planet7 = () => {
  return (
    <>
      <SpacePlanet7 />
    </>
  );
};

export default Planet7;
