import style from "./style.module.scss";

interface CategoryCardProps {
  image: string;
  title: string;
  desc: string;
  author: string;
  profileImage: string;
}

const CategoryCard = (props: CategoryCardProps) => {
  return (
    <div className={style.categoryCardWrap}>
      <div className={style.categoryCardContainer}>
        <div className={style.categoryCardImage}>
          <img
            src={process.env.PUBLIC_URL + "/image/" + `${props.image}.jpeg`}
            alt=""
          />
        </div>
        <div className={style.categoryCardText}>
          <div className={style.categoryCardTextItem}>
            <div className={style.categoryCardTextItemTitle}>{props.title}</div>
            <div className={style.categoryCardTextItemDesc}>{props.desc}</div>
          </div>
          <div className={style.categoryCardTextAuthor}>
            <div className={style.categoryCardTextAuthorName}>
              {props.author}
            </div>
            <img
              src={
                process.env.PUBLIC_URL +
                "/image/" +
                `${props.profileImage}.jpeg`
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
