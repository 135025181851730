import { ReactComponent as Polygon } from "./Polygon.svg";

const PolygonIcon = () => {
  return (
    <>
      <Polygon />
    </>
  );
};

export default PolygonIcon;
