import style from "./style.module.scss";
import React from "react";
import KyHeader from "../kyHeader/KyHeader";
import LockIcon from "../../../icon/lock/LockIcon";
import EthIcon from "../../../icon/Eth/Eth";
import PolyIcon from "../../../icon/poly/Poly";
import AvaxIcon from "../../../icon/avax/Avax";
import UsaIcon from "../../../icon/usa/Usa";
import TwitterIcon from "../../../icon/twitter/TwitterIcon";
import FacebookIcon from "../../../icon/facebook/FacebookIcon";
import InstagramIcon from "../../../icon/instagram/InstagramIcon";
import AppleIcon from "../../../icon/apple/Apple";
import GithubIcon from "../../../icon/github/Github";
import ThunderIcon from "../../../icon/thunbder/thunder";
import HeartIcon from "../../../icon/heart/Heart";
import StarIcon from "../../../icon/star/Star";
import BlinkIcon from "../../../icon/blink/Blink";
import FourArrowIcon from "../../../icon/fourArrow/FourArrow";
import { useNavigate } from "react-router-dom";
import { DemoProps } from "../../../lib/types";
import FingerIcon from "../../../icon/finger/Finger";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

const MyPage = (props: DemoProps) => {
  const history = useNavigate();
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);

  if (!isLoggedIn) {
    history("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  return (
    <section className={style.kyMyPageSection}>
      <KyHeader prefix={props.prefix} />
      <div
        className={style.kyMyPageBanner}
        onClick={() =>
          history("/experience/profile/openspace", {
            state: {
              from: "me",
            },
          })
        }
      >
        <div className={style.kyMyPageBannerButtonWrap}>
          <button
            className={style.kyMyPageBannerButton}
            onClick={() =>
              history("/experience/profile/openspace", {
                state: {
                  from: "me",
                },
              })
            }
          >
            Open Map
            <FourArrowIcon />
          </button>
          <div className={style.kyMyPageBannerButtonFinger}>
            <FingerIcon />
          </div>
        </div>
      </div>
      <div className={style.kyMyPageContent}>
        <div className={style.kyMyPageContentWrap}>
          <div className={style.kyMyPageContentLeft}>
            <div className={style.kyMyPageContentUserProfile}>
              <img src={process.env.PUBLIC_URL + "/image/myPageAvatar.jpeg"} />
            </div>
            <div className={style.kyMyPageContentUserInfo}>
              <div className={style.kyMyPageContentUserInfoDetails}>
                <div className={style.kyMyPageContentUserInfoName}>bitblur</div>
                <div className={style.kyMyPageContentUserInfoId}>
                  @bitblur.eth
                </div>
              </div>
              <div className={style.kyMyPageContentUserInfoPrivateButtonWrap}>
                <button className={style.kyMyPageContentUserInfoPrivateButton}>
                  <LockIcon />
                  <div>Private Message</div>
                </button>
              </div>
            </div>
            <div className={style.bioContainer}>
              <div className={style.bioTitle}>Bio</div>
              <div className={style.bioContent}>
                <div className={style.bioContentText1}>
                  Loves exploring the latest trends in digital art and
                  technology.
                </div>
                <div className={style.bioContentText2}>
                  Always on the lookout for new and exciting opportunities to
                  expand his knowledge and expertise in the digital world.
                </div>
              </div>
            </div>
            <div className={style.kyMyPageContentUserInfoBalanceWrap}>
              <div className={style.kyMyPageContentUserInfoBalance}>
                Balance
              </div>
              <div className={style.kyMyPageContentUserInfoBalanceValue}>
                <div
                  className={
                    style.kyMyPageContentUserInfoBalanceValueValueItems
                  }
                >
                  <EthIcon />
                  <div>6.32 ETH</div>
                </div>
                <div
                  className={
                    style.kyMyPageContentUserInfoBalanceValueValueItems
                  }
                >
                  <PolyIcon />
                  <div>902.49 MATIC</div>
                </div>
                <div
                  className={
                    style.kyMyPageContentUserInfoBalanceValueValueItems
                  }
                >
                  <AvaxIcon />
                  <div>322.01 AVAX</div>
                </div>
              </div>
            </div>
            <div className={style.kyMyPageContentUserLocation}>
              <div className={style.kyMyPageContentUserLocationTitle}>
                Location
              </div>
              <div className={style.kyMyPageContentUserLocationValue}>
                <UsaIcon />
                <div>New York, USA</div>
              </div>
            </div>
            <div className={style.kyMyPageContentUserInfoSns}>
              <TwitterIcon />
              <FacebookIcon />
              <InstagramIcon />
              <AppleIcon />
              <GithubIcon />
            </div>
          </div>
          <div className={style.kyMyPageContentRight}>
            <div className={style.kyMyPageContentRightValue}>
              <div className={style.kyMyPageContentRightValueWrap}>
                <div className={style.kyMyPageContentRightValueTitle}>
                  TechSavvyGal’s Value
                </div>
                <div className={style.kyMyPageContentRightValueScoreContainer}>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover1}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>KY Value</div>
                        <ThunderIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        18,198
                      </div>
                    </div>
                  </div>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>Supporters</div>
                        <HeartIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        128,245
                      </div>
                    </div>
                  </div>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>Supporting</div>
                        <StarIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        5,978153
                      </div>
                    </div>
                  </div>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>Points</div>
                        <BlinkIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        102,328,198
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.kyMyPageContentProfitImage}>
              <img src={process.env.PUBLIC_URL + "/image/profits.jpeg"} />
            </div>
            <img src={process.env.PUBLIC_URL + "/image/myCollections.jpeg"} />
          </div>
        </div>
        <div className={style.kyMyPageContentBottom}>
          <img src={process.env.PUBLIC_URL + "/image/feedSection.jpeg"} />
        </div>
      </div>
    </section>
  );
};

export default MyPage;
