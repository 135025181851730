import { ReactComponent as Menu } from "./menuIcon.svg";



const MenuIcon = () => {
    return (
        <div>
            <Menu />
        </div>
    );
};

export default MenuIcon;