import { ReactComponent as SpacePlanet9 } from "./spacePlanet9.svg";

const Planet9 = () => {
  return (
    <>
      <SpacePlanet9 />
    </>
  );
};

export default Planet9;
