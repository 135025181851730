import style from "./style.module.scss";

interface MarketCardProps {
  image: string;
  season: string;
  title: string;
  author: string;
  profileImage: string;
  key?: number;
  onClick?: () => void;
}
const MarketCard = (props: MarketCardProps) => {
  return (
    <div className={style.marketCardWrap} onClick={props.onClick}>
      <div>
        <div className={style.marketCardImage}>
          <img
            src={process.env.PUBLIC_URL + "/image/" + `${props.image}.jpeg`}
            alt=""
          />
        </div>
        <div className={style.marketCardTextWrap}>
          <div className={style.marketCardSeason}>{props.season}</div>
          <div className={style.marketCardTitle}>{props.title}</div>
          <div className={style.marketCardProfile}>
            <div className={style.marketCardAuthor}>{props.author}</div>
            <div className={style.marketCardProfileImage}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/image/" +
                  `${props.profileImage}.jpeg`
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketCard;
