import { ReactComponent as ChatProfile } from "./ChatProfile.svg";

const ChatProfileIcon = () => {
  return (
    <>
      <ChatProfile />
    </>
  );
};

export default ChatProfileIcon;
