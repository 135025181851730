import { ReactComponent as Usa } from "./Usa.svg";

const UsaIcon = () => {
  return (
    <>
      <Usa />
    </>
  );
};

export default UsaIcon;
