import { ReactComponent as Thunder } from "./Thunder.svg";

const ThunderIcon = () => {
  return (
    <>
      <Thunder />
    </>
  );
};

export default ThunderIcon;
