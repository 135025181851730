import Locale from "../../../component/locale/Locale";
import { LandingProps } from "../webLanding/WebLanding";
import { useCurrentLocale } from "../../../lib/function";

import "./style.scss";
import {
  image6Url,
  image7Url,
  image8Url,
  image9Url,
  mobileImage1,
  mobileImage6,
} from "../../../lib/image";

const MobileLanding = (props: LandingProps) => {
  const currentLocale = useCurrentLocale();

  const imageRenderer = () => {
    switch (props.isSelected) {
      case "":
        return (
          <div className="mobileMainCharacter">
            <img
              src={process.env.PUBLIC_URL + "/image/mobileCircle3.jpeg"}
              alt="main character"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/character1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet2.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet4.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/mobileCircle.jpeg"}
              alt="mainImage"
            />
          </div>
        );
        break;
      case "ky":
        return (
          <div className="mobileKyImage">
            <img
              src={process.env.PUBLIC_URL + "/image/character1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet2.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet4.jpeg"}
              alt="mainImage"
            />
          </div>
        );
        break;
      case "by":
        return (
          <div className="byImage">
            <img
              src={process.env.PUBLIC_URL + "./image/byCircle.jpeg"}
              alt="byBck"
            />
            <img
              className={props.isImageState}
              src={process.env.PUBLIC_URL + "./image/character2.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "./image/planet7.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "./image/planet9.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "./image/planet4.jpeg"}
              alt="mainImage"
            />
          </div>
        );
        break;
      default:
        return (
          <div className="mobileMainCharacter">
            <img
              src={process.env.PUBLIC_URL + "./image/mobileCircle.jpeg"}
              alt="main character"
            />
            <img
              src={process.env.PUBLIC_URL + "./image/character1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "./image/planet1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "./image/planet2.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "./image/planet4.jpeg"}
              alt="mainImage"
            />
          </div>
        );
    }
  };

  return (
    <article className="mobileLandingArticle">
      <div className={props.isLandingActiveHandler()}>
        <p className="mobileLandingTextP">FIND YOUR FAN AND GROW TOGETHER</p>
        <h1 className="mobileLandingTextH1">WEB3 SPACE</h1>
        <h1 className="mobileLandingTextH2">FOR YOURSELF</h1>
        <div className="mobileLandingTextLast">Meet you soon !</div>
      </div>
      <div className={props.isKYActiveHandler()}>
        <div className={props.isStyleChange()}>
          <div className="mobileKyHeaderItem">
            <Locale tag="ky_landing_header1" />
          </div>
          <div className="mobileKyHeaderItem2">
            <div>
              <Locale tag="ky_landing_ky" />
            </div>
            <div>
              <Locale tag="ky_landing_header2" />
            </div>
          </div>
          <Locale tag="ky_landing_header3" />
          <div className="mobileKyHeaderItem"></div>
        </div>
        <div className={props.isPtagStyleChange()}>
          <div className="mobileKyPItem">
            <div>
              <Locale tag="ky_landing_p1" />
            </div>
            <div>
              <Locale tag="ky_landing_p2" />
            </div>
          </div>
          <div className="mobileKyPItem">
            <div>
              <Locale tag="ky_landing_p3" />
            </div>
            <div>
              <Locale tag="ky_landing_p4" />
            </div>
          </div>
          <div className="mobileKyPItem">
            <div>
              <Locale tag="ky_landing_p5" />
            </div>
            <div>
              <Locale tag="ky_landing_p6" />
            </div>
          </div>
        </div>
      </div>
      <div className={props.isByActiveHandler()}>
        <div
          className={currentLocale === "en" ? "byHeaderWrap" : "byHeaderWrapKo"}
        >
          {currentLocale === "en" ? (
            <div>
              On the <span>Know-YourSelf </span>
              <Locale tag="ky_by_h1" />
            </div>
          ) : (
            <div>
              <span>Know-YourSelf </span>
              <Locale tag="ky_by_h1" />
              <div>
                <Locale tag="ky_by_h2" />
              </div>
            </div>
          )}
        </div>
        <div className={currentLocale === "en" ? "byPHeader" : "byPHeaderKo"}>
          <div>
            <Locale tag="ky_by_p1" />
          </div>
          <div>{currentLocale === "en" && <Locale tag="ky_by_p2" />}</div>
        </div>
        <div className={currentLocale === "en" ? "byList" : "byListKo"}>
          <div>
            <Locale tag="ky_by_p3" />
          </div>

          <div>
            <Locale tag="ky_by_p4" />
          </div>
          <div>
            <Locale tag="ky_by_p5" />
          </div>
          <div>
            <Locale tag="ky_by_p6" />
          </div>
          <div>
            <Locale tag="ky_by_p7" />
          </div>
          <div>
            <Locale tag="ky_by_p8" />
          </div>
        </div>
      </div>
      {imageRenderer()}
    </article>
  );
};

export default MobileLanding;
