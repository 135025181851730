import { ReactComponent as DownArrow } from "./DownArrow.svg";

const DownArrowIcon = () => {
  return (
    <>
      <DownArrow />
    </>
  );
};

export default DownArrowIcon;
