import { ReactComponent as KyLogoSvg } from "./KyParkingLogo.svg";

const KyLogo = () => {
    return (
        <>
            <KyLogoSvg />
        </>
    );
};

export default KyLogo;
