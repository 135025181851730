import { ReactComponent as Out } from "./Out.svg";

const OutIcon = () => {
  return (
    <>
      <Out />
    </>
  );
};

export default OutIcon;
