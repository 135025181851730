import { ReactComponent as ShowAll } from "./ShowAll.svg";

const ShowAllIcon = () => {
  return (
    <>
      <ShowAll />
    </>
  );
};

export default ShowAll;
