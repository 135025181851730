import "./style.scss";
import Locale from "../../component/locale/Locale";
import { useCurrentLocale } from "../../lib/function";
import React, { useEffect, useState } from "react";
import WebLanding from "./webLanding/WebLanding";
import MobileLanding from "./mobileLanding/MobileLanding";
import Header from "../../component/header/Header";
import Lottie from "lottie-react";
import Loader from "../../lottie/planetLoader.json";
import Modal from "../../component/modal/Modal";
import HardModal from "../../component/hardModal/HardModal";

interface LandingPageProps {
  isSelected: string;
  prefix: string | undefined;
  setIsSelected: (value: string) => void;
}

const LandingPage = ({
  isSelected,
  setIsSelected,
  prefix,
}: LandingPageProps): JSX.Element => {
  const [isImageState, setImageState] = useState("byCharacter disabled");
  const currentLocale = useCurrentLocale();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(true);

  const isStyleChange = () => {
    if (currentLocale === "ko") return "kyHeaderKo";
    else return "kyHeader";
  };

  const isPtagStyleChange = () => {
    if (currentLocale === "ko") return "kyPWrapKo";
    else return "kyPWrap";
  };

  const isLandingActiveHandler = () => {
    if (isSelected === "") {
      return `landingTextWrap show`;
    }
    if (isSelected === "ky" || isSelected === "by") {
      return `landingTextWrap disabled`;
    }

    return "";
  };

  const isKYActiveHandler = () => {
    if (isSelected === "" || isSelected === "by") {
      return `kyText disabled`;
    } else if (isSelected === "ky") {
      return `kyText show`;
    }
    return "";
  };

  const isByActiveHandler = () => {
    if (currentLocale === "ko") {
      if (isSelected === "" || isSelected === "ky") {
        return "byWrap disabled";
      } else if (isSelected === "by") {
        return "byWrap show ko";
      }
    } else {
      if (isSelected === "" || isSelected === "ky") {
        return "byWrap disabled";
      } else if (isSelected === "by") {
        return "byWrap show";
      }
    }
    return "";
  };

  const imageDelayRenderer = () => {
    setTimeout(() => setImageState("byCharacter show"));
  };

  useEffect(() => {
    imageDelayRenderer();
  }, []);

  const landingRenderrer = () => {
    if (prefix) {
      switch (prefix) {
        case "xxl":
        case "xl":
        case "md":
          return (
            <WebLanding
              isLandingActiveHandler={isLandingActiveHandler}
              isKYActiveHandler={isKYActiveHandler}
              isStyleChange={isStyleChange}
              isPtagStyleChange={isPtagStyleChange}
              isByActiveHandler={isByActiveHandler}
              isSelected={isSelected}
              isImageState={isImageState}
            />
          );
          break;
        case "sm":
          return (
            <MobileLanding
              isLandingActiveHandler={isLandingActiveHandler}
              isKYActiveHandler={isKYActiveHandler}
              isStyleChange={isStyleChange}
              isPtagStyleChange={isPtagStyleChange}
              isByActiveHandler={isByActiveHandler}
              isSelected={isSelected}
              isImageState={isImageState}
            />
          );
      }
    }
  };

  return (
    <section className="landingSection">
      <Header
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        prefix={prefix}
        setIsLoginModalOpen={setIsLoginModalOpen}
      />
      {landingRenderrer()}
    </section>
  );
};

export default LandingPage;
