import { ReactComponent as RightDrop } from "./RightDrop.svg";

const RightDropIcon = () => {
  return (
    <>
      <RightDrop />
    </>
  );
};

export default RightDropIcon;
