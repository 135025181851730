import { ReactComponent as Star } from "./Star.svg";

const StarIcon = () => {
  return (
    <>
      <Star />
    </>
  );
};

export default StarIcon;
