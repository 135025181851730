import { ReactComponent as Bell } from "./Bell.svg";

const BellIcon = () => {
  return (
    <>
      <Bell />
    </>
  );
};

export default BellIcon;
