import { ReactComponent as DotDotDot } from "./DotDotDot.svg";

const DotDotDotIcon = () => {
  return (
    <>
      <DotDotDot />
    </>
  );
};

export default DotDotDotIcon;
