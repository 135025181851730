import style from "./style.module.scss";
import React, { useEffect } from "react";
import KyHeader from "../kyHeader/KyHeader";
import RightArrowIcon from "../../../icon/rightArrow/RightArrow";
import { DemoProps } from "../../../lib/types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

const KyWorld = (props: DemoProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  return (
    <section className={style.worldSection}>
      <KyHeader prefix={props.prefix} isPoint="logo" />
      <article className={style.worldArticle}>
        <div className={style.worldWrap}>
          <div className={style.worldHeaderImage}>
            <img
              src={process.env.PUBLIC_URL + "/image/worldHeaderImage.jpeg"}
            />
          </div>
          <div className={style.worldHeaderText}>
            <div className={style.worldHeaderTexContainer}>
              <div className={style.worldHeaderTextWrap}>
                <div className={style.worldHeaderTextTitle}>
                  [Season 3] Unleash Your
                </div>
                <div className={style.worldHeaderTextTitle}>Imagination</div>
              </div>
              <div className={style.worldHeaderTextDescWrap}>
                <div className={style.worldHeaderTextDesc}>
                  Join our Creative Character Contest!
                </div>
                <div className={style.worldHeaderTextDesc}>
                  Open to all aspring artists, writers, and creators!
                </div>
              </div>
            </div>
            <div className={style.worldHeaderBtnWrap}>
              <button className={style.worldHeaderBtn}>
                <RightArrowIcon />
                Detail
              </button>
              <button className={style.worldHeaderBtn2}>Apply</button>
            </div>
          </div>
        </div>
      </article>
      <article className={style.eventArticle}>
        <div className={style.eventWrap}>
          <div className={style.eventTitle}>Event & News</div>
          <div className={style.eventImageWrap}>
            <div className={style.imageContainer}>
              <div className={style.overay}>
                <img src={process.env.PUBLIC_URL + "/image/lulu.jpeg"} />
              </div>
            </div>
            <div className={style.imageContainer2}>
              <img src={process.env.PUBLIC_URL + "/image/binoculars.jpeg"} />
            </div>
            <div className={style.imageContainer3}>
              <div className={style.overay}>
                <img
                  src={process.env.PUBLIC_URL + "/image/twins.jpeg"}
                  className={style.image1}
                />
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};
export default KyWorld;
