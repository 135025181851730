import { ReactComponent as Checked } from "./Checked.svg";

const CheckedIcon = () => {
  return (
    <>
      <Checked />
    </>
  );
};

export default CheckedIcon;
