import { ReactComponent as SilverPlanet } from "./SilverPlanet.svg";

const SilverPlanetImage = () => {
  return (
    <>
      <SilverPlanet />
    </>
  );
};

export default SilverPlanetImage;
