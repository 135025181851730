import { useEffect } from "react";

const NotFoundPage = () => {
  useEffect(() => {
    window.location.href = "/";
  }, []);

  return <></>;
};

export default NotFoundPage;
