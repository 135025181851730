import { ReactComponent as SpacePlanet3 } from "./spacePlanet3.svg";

const Planet3 = () => {
  return (
    <>
      <SpacePlanet3 />
    </>
  );
};

export default Planet3;
