import style from "./style.module.scss";
import { StepProps } from "../step1/Step1";
import SmallColorPlanet from "../../../../../icon/smallColorPlanet/SmallColorPlanet";
import Finger from "../../../../../icon/finger/Finger";
const Step5 = (props: StepProps) => {
  return (
    <article className={style.step5ArticleWrap}>
      <div className={style.step5PlanetContainer}>
        <div className={style.step5Header}>Sale Planet</div>
        <div className={style.step5PlanetComponent}>
          <div className={style.step5PlanetWrap}>
            <SmallColorPlanet />
          </div>
          <div className={style.step5User}>
            <div className={style.step5userInfo}>
              <div className={style.step5userName}>bitBlur</div>
              <div className={style.step5userItem}>Stardust Seraph</div>
              <div className={style.step5userId}>#13728121</div>
            </div>
            <div className={style.step5userPaymentInfo}>
              <div className={style.step5paymentInfoWrap}>
                <div className={style.step5infoItem1}>Chain</div>
                <div className={style.step5infoItem2}>Polygon</div>
              </div>
              <div className={style.step5paymentInfoWrap}>
                <div className={style.step5infoItem1}>Total Pieces</div>
                <div className={style.step5infoItem2}>888</div>
              </div>
              <div className={style.pstep5paymentInfoWrap2}>
                <div className={style.step5infoItem1}>Create</div>
                <div className={style.step5infoItem2}>May 12, 2023</div>
              </div>
            </div>
          </div>
          <div className={style.step5Buttons}>
            <button
              className={style.step5myBtn}
              onClick={() => {
                window.location.href = "/experience/marketplace";
              }}
            >
              Go to Marketplace
            </button>
            <div className={style.step5saleFinger}>
              <button
                className={style.step5saleBtn}
                onClick={() => {
                  window.location.href = "/experience/planetinfo";
                }}
              >
                Go to My Collection
              </button>
              <div className={style.fingerWrap}>
                <Finger />
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Step5;
