import { ReactComponent as Github } from "./Github.svg";

const GithubIcon = () => {
  return (
    <>
      <Github />
    </>
  );
};

export default GithubIcon;
