import KyLanding from "./kyLanding/KyLanding";
import KyHeader from "./kyHeader/KyHeader";
import style from "./style.module.scss";
import { DemoProps } from "../../lib/types";

const KyDemo = (props: DemoProps) => {
  return (
    <main className={style.kyMain}>
      <KyHeader prefix={props.prefix} />
      <KyLanding />
    </main>
  );
};

export default KyDemo;
