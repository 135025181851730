import { ReactComponent as Heart } from "./Heart.svg";

const HeartIcon = () => {
  return (
    <>
      <Heart />
    </>
  );
};

export default HeartIcon;
