import { ReactComponent as Lock } from "./Lock.svg";

const LockIcon = () => {
  return (
    <>
      <Lock />
    </>
  );
};

export default LockIcon;
