import style from "./style.module.scss";
import sunLottie from "../../../../../lottie/solarLottie.json";
import Lottie from "lottie-react";
import { Dispatch, SetStateAction } from "react";
import FingerIcon from "../../../../../icon/finger/Finger";

export interface StepProps {
  setIsStep: Dispatch<SetStateAction<number>>;
}
const Step1 = (props: StepProps) => {
  return (
    <article className={style.step1Article}>
      <div className={style.lottieArea}>
        <div className={style.mintPlanet}>Mint My Planet</div>
        <Lottie animationData={sunLottie} />
      </div>
      <div className={style.descWrap}>
        <div className={style.step1Desc}>
          <div className={style.step1DescItem}>From now,</div>
          <div className={style.step1DescItem}>
            your planet is your new identity.
          </div>
        </div>
        <div className={style.step1SubDesc}>
          <div className={style.step1SubDescItems}>
            Know-Yourself connects individual identity and value to various
          </div>
          <div className={style.step1SubDescItems}>
            services through NFTs. And it represents and trades individual
            values
          </div>
          <div className={style.step1SubDescItems}>
            formed in numerous relationships through on-chain.
          </div>
        </div>
      </div>

      <div className={style.startBtnWrap}>
        <button className={style.startBtn} onClick={() => props.setIsStep(2)}>
          Start
        </button>
        <div className={style.fingerIcon}>
          <FingerIcon transform="rotate(0)" />
        </div>
      </div>
    </article>
  );
};

export default Step1;
