import { StepProps } from "../step1/Step1";
import style from "./style.module.scss";
import ColorPlanet from "../../../../../icon/colorPlanet/ColorPlanet";
import PolygonIcon from "../../../../../icon/polygon/PolygonIcon";
import { useState } from "react";
import Lottie from "lottie-react";
import Loader from "../../../../../lottie/planetLoader.json";
import CompleteIcon from "../../../../../icon/complete/CompleteIcon";
import Modal from "../../../../../component/modal/Modal";
import Planet1 from "../../../../../icon/mainPlanet/MainPlanet";
import FingerIcon from "../../../../../icon/finger/Finger";
import FingerLeftIcon from "../../../../../icon/fingerLeft/fingerLeft";
const Step4 = (props: StepProps) => {
  const [isSaleConfirmModalOpen, setIsSaleConfirmModalOpen] = useState(false);
  const [isSaleCompleteModalOpen, setIsSaleCompleteModalOpen] = useState(false);
  const [isSaleLoading, setIsSaleLoading] = useState(false);
  const [fingerStep, setFingerStep] = useState(1);
  const isSaleCompleteModal = isSaleLoading ? (
    <div className={style.loaderWrap}>
      <div className={style.lottieWrap}>
        <Lottie animationData={Loader} />
      </div>
      <div>Loading ...</div>
    </div>
  ) : (
    <div className={style.completeModalContainer}>
      <div className={style.completeHeaderWrap}>
        <div>
          <CompleteIcon />
        </div>
        <div>Complete!</div>
      </div>
      <div className={style.bodyWrap}>
        <div className={style.txWrap1}>
          <div className={style.txItem1}>Tx Hash</div>
          <div className={style.txItem2}>9C765C...3C5EDC</div>
        </div>
        <div className={style.txWrap2}>
          <div className={style.txItem1}>Tx Fee</div>
          <div className={style.txItem2}>0 MATIC</div>
        </div>
      </div>
    </div>
  );
  const isSaleConfirmModal = (
    <div className={style.confirmModalContainer}>
      <div className={style.modalTextWrap}>
        <div className={style.modalHeader}>
          <div className={style.modalTitle}>Would you like to sell your</div>
          <div className={style.modalTitle}>Planet?</div>
        </div>
        <div className={style.modalDescWrap}>
          <div className={style.modalDescription}>
            Each transaction will incur non-
          </div>
          <div className={style.modalDescription}>
            refundable gas fees. You can;t undo this
          </div>
          <div className={style.modalDescription}>action later.</div>
        </div>
      </div>
      <div className={style.chainInfoWrap}>
        <div className={style.chainInfoContainer}>
          <div className={style.polygonIcon}>
            <PolygonIcon />
            <div className={style.polygonInfoWrap}>
              <div>0x9876...4321ba</div>
              <div>Polygon</div>
            </div>
          </div>
          <button className={style.connectedButton}>Connected</button>
        </div>
      </div>
      <div className={style.priceInfoWrap}>
        <div className={style.priceInfoContainer}>
          <div className={style.infoItem1}>Price</div>
          <div className={style.infoItem2}>0 MATIC</div>
        </div>
        <div className={style.feeInfoContainer}>
          <div className={style.infoItem1}>Fee</div>
          <div className={style.infoItem2}>0%</div>
        </div>
        <div className={style.paymentInfoContainer}>
          <div className={style.infoItem1}>You Will Pay</div>
          <div>0</div>
        </div>
      </div>
      <div className={style.modalBtnWrap}>
        <button
          className={style.cancelBtn}
          onClick={() => setIsSaleConfirmModalOpen(false)}
        >
          Cancel
        </button>
        <button
          className={style.confirmBtn}
          onClick={() => {
            setIsSaleConfirmModalOpen(false);
            setIsSaleLoading(true);
            setIsSaleCompleteModalOpen(true);
            setTimeout(() => {
              setIsSaleLoading(false);
            }, 3000);
            setTimeout(() => {
              setIsSaleCompleteModalOpen(false);
            }, 6000);
            setTimeout(() => {
              props.setIsStep(5);
            }, 7000);
          }}
        >
          Confirm
        </button>
        <div className={style.finger2Wrap}>
          {fingerStep === 2 && <FingerLeftIcon transform="rotate(270)" />}
        </div>
      </div>
    </div>
  );

  return (
    <article className={style.step4ArticleWrap}>
      <div className={style.step4Header}>Sale Your Planet</div>
      <div className={style.step4PlanetWrap}>
        <div className={style.step4PlanetContainer}>
          <Planet1 width="450" height="450" />
          <div className={style.step4User}>
            <div className={style.userName}>biblur</div>
            <div className={style.userId}>#13728121</div>
          </div>
        </div>
        <div className={style.step4Script}>
          <div className={style.scriptHeader}>Set Your Sale Plan</div>
          <div className={style.scriptContent}>
            <div className={style.scriptContentText}>
              Your planet can be sold as a fractionalized NFT based on a value
              you
            </div>
            <div className={style.scriptContentText}>
              set. By purchasing your fractionalized NFTs, other users gain
              access to{" "}
            </div>
            <div className={style.scriptContentText}>
              the membership benefits you offer and the right to participate in
              the{" "}
            </div>
            <div className={style.scriptContentText}>
              governance that determines the rules for membership.
            </div>
          </div>
          <div className={style.scriptPayment}>
            <div className={style.scriptPaymentItem}>
              <div className={style.scriptPaymentTitle}>
                Total Planet Pieces
              </div>
              <div className={style.inputWrap}>
                <input
                  className={style.colorInput}
                  value="140,000"
                  disabled={true}
                />
              </div>
            </div>
            <div className={style.scriptPaymentItem}>
              <div className={style.scriptPaymentTitle}>Available Pieces</div>
              <div className={style.inputWrap}>
                <input
                  className={style.colorInput}
                  value="78,888"
                  disabled={true}
                />
              </div>
            </div>
            <div className={style.scriptPaymentItem}>
              <div className={style.scriptPaymentTitle}>Season 6 Sales</div>
              <div className={style.inputWrap}>
                <input
                  className={style.colorInput}
                  value="888"
                  disabled={true}
                />
              </div>
            </div>
            <div className={style.scriptPaymentItem}>
              <div className={style.scriptPaymentTitle}>Set Sales Date</div>
              <div className={style.inputWrap}>
                <input
                  className={style.colorInput}
                  value="12 / 05 / 2023"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.step4SaleButton}>
        <button
          onClick={() => {
            setIsSaleConfirmModalOpen(true);
            setFingerStep(2);
          }}
          className={style.saleButton}
        >
          Sale Your Planet
        </button>
        {fingerStep === 1 && (
          <div className={style.fingerWrap1}>
            <FingerLeftIcon />
          </div>
        )}
      </div>
      <Modal
        children={isSaleConfirmModal}
        isModalOpen={isSaleConfirmModalOpen}
      />
      <Modal
        children={isSaleCompleteModal}
        isModalOpen={isSaleCompleteModalOpen}
      />
    </article>
  );
};

export default Step4;
