import { ReactComponent as SpacePlanet8 } from "./spacePlanet8.svg";

const Planet8 = () => {
  return (
    <>
      <SpacePlanet8 />
    </>
  );
};

export default Planet8;
