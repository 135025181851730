import "./style.scss";
import MobileTranslateIcon from "../../../icon/mobileTranslate/MobileTranslateIcon";
import MenuIcon from "../../../icon/menuIcon/MenuIcon";
import { WebHeaderProps } from "../WebHeader/WebHeader";
import { useState } from "react";
import CloseIcon from "../../../icon/close/CloseIcon";
import MobileLogo from "../../../icon/mobileLogo/MobileLogo";

const MobileHeader = (props: WebHeaderProps) => {
  const [isSideMenuStatus, setIsSideMenuStatus] = useState("ready");

  const isSideMenuHandler = () => {
    if (isSideMenuStatus === "ready" || isSideMenuStatus === "close") {
      setIsSideMenuStatus("open");
    } else {
      setIsSideMenuStatus("close");
    }
  };

  const isSelectHandler = (el: string) => {
    setIsSideMenuStatus("close");
    switch (el) {
      case "ky":
        return props.setIsSelected("ky");
        break;
      case "by":
        return props.setIsSelected("by");
        break;
      default:
        return;
    }
  };
  return (
    <div className="mobileHeaderContainer">
      <div className="mobileHeaderWrap">
        <div className="mobileLogo" onClick={() => props.setIsSelected("")}>
          <MobileLogo />
        </div>
        <nav className="mobileNav">
          <div onClick={(event) => props.isMenuHandler(event)}>
            <MobileTranslateIcon />
          </div>
          <div onClick={() => isSideMenuHandler()}>
            <MenuIcon />
          </div>
        </nav>
      </div>
      {props.isMenuState && (
        <div
          className={`mobileLanguageMenu ${props.isMenuState ? "active" : ""}`}
        >
          <div
            className={props.isEnSelected}
            onClick={() => props.languageChangeToEn()}
          >
            ENG
          </div>
          <div
            className={props.isKoSelected}
            onClick={() => props.languageChangeToKo()}
          >
            KOR
          </div>
        </div>
      )}
      <div className={`sideMenu ${isSideMenuStatus}`}>
        <div className="sideMenuContainer">
          <div
            className="closeIcon"
            onClick={() => setIsSideMenuStatus("close")}
          >
            <CloseIcon />
          </div>
          <nav className="sideMenuNav">
            <div className="sideMenuItem" onClick={() => isSelectHandler("ky")}>
              Know Yourself
            </div>
            <div className="sideMenuItem" onClick={() => isSelectHandler("by")}>
              Be Yourself
            </div>
            <div
              className="sideMenuItem"
              onClick={() => {
                window.location.href = "/bitbluelock";
              }}
            >
              Experience
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
