import { ReactComponent as SpacePlanet5 } from "./spacePlanet5.svg";

const Planet5 = () => {
  return (
    <>
      <SpacePlanet5 />
    </>
  );
};

export default Planet5;
