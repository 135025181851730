import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import style from "./style.module.scss";
import Planet1 from "../../../icon/mainPlanet/MainPlanet";
import Planet2 from "../../../icon/planets/Planet2";
import Planet3 from "../../../icon/planets/Planet3";
import Planet4 from "../../../icon/planets/Planet4";
import Planet5 from "../../../icon/planets/Planet5";
import Planet6 from "../../../icon/planets/Planet6";
import Planet7 from "../../../icon/planets/Planet7";
import Planet8 from "../../../icon/planets/Planet8";
import Planet9 from "../../../icon/planets/Planet9";
import KyHeader from "../kyHeader/KyHeader";
import GoToProfile from "../../../icon/goToProfile/GoToProfile";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import Lottie from "lottie-react";
import circlePlanet from "../../../lottie/circlePlanet.json";
import {
  userConnectedData,
  userSavvyConnectedData,
} from "../../../lib/planetData";
import { DemoProps } from "../../../lib/types";
import FingerIcon from "../../../icon/finger/Finger";
import HardModal from "../../../component/hardModal/HardModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

const Space = (props: DemoProps) => {
  let planet1Ref = useRef<HTMLDivElement>(null);
  let planetContainerRef = useRef<HTMLDivElement>(null);
  let planet2Ref = useRef<HTMLDivElement>(null);
  let planet3Ref = useRef<HTMLDivElement>(null);
  let planet4Ref = useRef<HTMLDivElement>(null);
  let planet5Ref = useRef<HTMLDivElement>(null);
  let planet6Ref = useRef<HTMLDivElement>(null);
  let planet7Ref = useRef<HTMLDivElement>(null);
  let planet8Ref = useRef<HTMLDivElement>(null);
  let planet9Ref = useRef<HTMLDivElement>(null);
  let lottieRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);

  const useLocationWithDefault = () => {
    const location = useLocation();

    if (!location.state) {
      location.state = { from: "me" };
    }

    return location;
  };
  const history = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: circlePlanet,
  };
  const location = useLocationWithDefault();

  const [isPlanet2size, setIsPlanet2size] = React.useState<string>("100");
  const [isPlanet1size, setIsPlanet1size] = React.useState<string>("250");
  const [isSheild, setIsShield] = React.useState<boolean>(false);
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const [isButtonOn, setIsButtonOn] = React.useState<boolean>(false);
  const [isClickCount, setIsClickCount] = React.useState<number>(0);
  const isIncreaseSize = () => {
    let currentSize = 100;
    const interval = setInterval(() => {
      setIsPlanet2size(currentSize.toString());
      currentSize++;
      if (currentSize > 250) {
        clearInterval(interval);
      }
    }, 3);
  };

  const isDisIncreaseSize = () => {
    let currentSize = 250;
    const interval = setInterval(() => {
      setIsPlanet1size(currentSize.toString());
      currentSize--;
      if (currentSize < 100) {
        clearInterval(interval);
      }
    }, 3);
  };

  const switchPlaces = () => {
    if (
      !isSheild &&
      planetContainerRef.current &&
      planet1Ref.current &&
      planet2Ref.current
    ) {
      const containerWidth = planetContainerRef.current.offsetWidth;
      const containerHeight = planetContainerRef.current.offsetHeight;
      if (isClicked) {
        return;
      }
      const isXCenter = (planetSize: number) => {
        return containerWidth / 2 - planetSize / 2;
      };

      const isYCenter = (planetSize: number) => {
        return containerHeight / 2 - planetSize / 2;
      };

      isIncreaseSize();
      isDisIncreaseSize();

      gsap.to(planet1Ref.current, {
        x: isXCenter(100) - 320,
        y: isYCenter(100) + 50,
        duration: 2,
        position: "absolute",
      });
      gsap.to(planet2Ref.current, {
        x: isXCenter(350),
        y: isYCenter(350) + 70,
        duration: 2,
        position: "absolute",
      });

      gsap.to(planet3Ref.current, {
        x: isXCenter(122) + 400,
        y: isYCenter(122) - 300,
        position: "absolute",
        duration: 2,
      });
      gsap.to(planet4Ref.current, {
        x: isXCenter(122) - 300,
        y: isYCenter(122) + 361,
        position: "absolute",
        duration: 2,
      });
      gsap.to(planet5Ref.current, {
        x: isXCenter(35) - 327,
        y: isYCenter(35) + 180,
        position: "absolute",
        duration: 2,
      });
      gsap.to(planet6Ref.current, {
        x: isXCenter(35) + 690,
        y: isYCenter(35),
        position: "absolute",
        duration: 2,
      });
      gsap.to(planet7Ref.current, {
        x: isXCenter(70) - 640,
        y: isYCenter(70) - 200,
        position: "absolute",
        duration: 2,
      });
      gsap.to(planet8Ref.current, {
        x: isXCenter(70) + 700,
        y: isYCenter(70) - 380,
        position: "absolute",
        duration: 2,
      });
      gsap.to(planet9Ref.current, {
        x: isXCenter(93) - 500,
        y: isYCenter(93) - 300,
        position: "absolute",
        duration: 2,
      });
      setTimeout(() => {
        setIsClickCount(isClickCount + 1);
      }, 100);
      setIsShield(true);
      setIsClicked(true);
    }
  };

  const isClickedResize = (
    containerWidth: number,
    containerHeight: number,
    lottieWidth: number,
    lottieHeight: number
  ) => {
    const isXCenter = (planetSize: number) => {
      return containerWidth / 2 - planetSize / 2;
    };

    const isYCenter = (planetSize: number) => {
      return containerHeight / 2 - planetSize / 2;
    };
    const ClickedPlanetPosition = () => {
      gsap.set(planet2Ref.current, {
        x: isXCenter(350),
        y: isYCenter(350),
        position: "absolute",
      });

      gsap.set(planet1Ref.current, {
        x: isXCenter(100) - 320,
        y: isYCenter(100) + 50,
      });

      gsap.set(planet3Ref.current, {
        x: isXCenter(122) + 400,
        y: isYCenter(122) - 300,
        position: "absolute",
        duration: 2,
      });
      gsap.set(planet4Ref.current, {
        x: isXCenter(122) - 300,
        y: isYCenter(122) + 361,
        position: "absolute",
        duration: 2,
      });
      gsap.set(planet5Ref.current, {
        x: isXCenter(35) - 327,
        y: isYCenter(35) + 180,
        position: "absolute",
        duration: 2,
      });
      gsap.set(planet6Ref.current, {
        x: isXCenter(35) + 690,
        y: isYCenter(35),
        position: "absolute",
        duration: 2,
      });
      gsap.set(planet7Ref.current, {
        x: isXCenter(70) - 640,
        y: isYCenter(70) - 200,
        position: "absolute",
        duration: 2,
      });
      gsap.set(planet8Ref.current, {
        x: isXCenter(70) + 700,
        y: isYCenter(70) - 380,
        position: "absolute",
        duration: 2,
      });
      gsap.set(planet9Ref.current, {
        x: isXCenter(93) - 500,
        y: isYCenter(93) - 300,
        position: "absolute",
        duration: 2,
      });
    };
    ClickedPlanetPosition();
  };
  const handleResize = (
    containerWidth: number,
    containerHeight: number,
    lottieWidth: number,
    lottieHeight: number
  ) => {
    let from = location.state.from;
    const isXCenter = (planetSize: number) => {
      return containerWidth / 2 - planetSize / 2;
    };

    const isYCenter = (planetSize: number) => {
      return containerHeight / 2 - planetSize / 2;
    };

    const basicPlanetPosition = () => {
      gsap.set(planet3Ref.current, {
        x: isXCenter(122) - 400,
        y: isYCenter(122) + 300,
        position: "absolute",
      });
      gsap.set(planet4Ref.current, {
        x: isXCenter(122) + 300,
        y: isYCenter(122) - 361,
        position: "absolute",
      });
      gsap.set(planet5Ref.current, {
        x: isXCenter(35) + 327,
        y: isYCenter(35) - 180,
        position: "absolute",
      });
      gsap.set(planet6Ref.current, {
        x: isXCenter(35) - 690,
        y: isYCenter(35),
        position: "absolute",
      });
      gsap.set(planet7Ref.current, {
        x: isXCenter(70) + 640,
        y: isYCenter(70) + 200,
        position: "absolute",
      });
      gsap.set(planet8Ref.current, {
        x: isXCenter(70) - 700,
        y: isYCenter(70) + 380,
        position: "absolute",
      });
      gsap.set(planet9Ref.current, {
        x: isXCenter(93) + 500,
        y: isYCenter(93) + 300,
        position: "absolute",
      });
    };

    if (from === "me") {
      gsap.set(planet1Ref.current, {
        x: isXCenter(350) - 10,
        y: isYCenter(350) + 50,
        position: "absolute",
      });

      gsap.set(planet2Ref.current, {
        x: isXCenter(100) - 320,
        y: isYCenter(100) + 50,
      });

      basicPlanetPosition();
    } else {
      gsap.set(planet2Ref.current, {
        x: isXCenter(350) - 100,
        y: isYCenter(350),
        position: "absolute",
      });

      gsap.set(planet1Ref.current, {
        x: isXCenter(100) - 320,
        y: isYCenter(100) + 50,
      });
    }
  };

  useEffect(() => {
    const handleResizeClosure = () => {
      if (planetContainerRef.current && lottieRef.current) {
        const containerWidth = planetContainerRef.current.offsetWidth;
        const containerHeight = planetContainerRef.current.offsetHeight;
        const lottieWidth = lottieRef.current.offsetWidth;
        const lottieHeight = lottieRef.current.offsetHeight;
        const FriendPlanetPosition = () => {
          setIsPlanet2size("350");
          setIsPlanet1size("100");
          setIsShield(true);
          setIsClicked(true);
          isClickedResize(
            containerWidth,
            containerHeight,
            lottieWidth,
            lottieHeight
          );
        };
        if (isClicked && isClickCount === 1) {
          isClickedResize(
            containerWidth,
            containerHeight,
            lottieWidth,
            lottieHeight
          );
        } else if (location.state.from === "me") {
          handleResize(
            containerWidth,
            containerHeight,
            lottieWidth,
            lottieHeight
          );
        } else {
          FriendPlanetPosition();
        }
      }
    };

    handleResizeClosure();

    window.addEventListener("resize", handleResizeClosure);
    return () => window.removeEventListener("resize", handleResizeClosure);
  }, [planetContainerRef.current, isClicked, isClickCount]);

  const isFriend = location.state.from !== "me";

  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/bitbluelock");
    return null; // 렌더링하지 않음
  }

  return (
    <section className={style.spaceSection}>
      <div className={style.commonHeaderWrap}>
        <KyHeader prefix={props.prefix} />
      </div>
      <div className={style.lottieBack} ref={lottieRef}>
        <Lottie animationData={circlePlanet} />
      </div>
      <div className={style.SpaceHeaderWrap}>
        <div className={style.SpaceHeader}>Explore Universe</div>
        <div className={style.SpaceHeaderDesc}>
          <div>ID: digitalexplorer.eth</div>
          <div>Planet: Stellarion Cascade</div>
          <div>NFT: #1391702</div>
        </div>
      </div>
      <div className={style.planetContainer} ref={planetContainerRef}>
        <div className={style.planet} ref={planet1Ref}>
          <Planet1 width={isPlanet1size} height={isPlanet1size} />
          <div
            className={
              isClicked && !isFriend
                ? style.planetUserInfoWrapNot
                : style.planetUserInfoWrap
            }
          >
            <div
              className={
                isClicked && !isFriend
                  ? style.planetUserInfoContainerNot
                  : style.planetUserInfoContainer
              }
            >
              <div className={style.planetUserInfoImage}>
                <img
                  src={process.env.PUBLIC_URL + "/image/planetAvatar.jpeg"}
                  alt="Avatar"
                />
              </div>
              <div className={style.planetUserInfoDetails}>
                <div className={style.planet1UserInfoName}>bitBlur</div>
                {!isClicked && !isFriend && (
                  <div className={style.planet2UserInfoId}>@bitblur.eth</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={style.planet}
          ref={planet2Ref}
          onClick={() => switchPlaces()}
        >
          <Planet2 width={isPlanet2size} height={isPlanet2size} />
          <div
            className={
              isClicked || isFriend
                ? style.planetUserInfoWrap
                : style.planetUserInfoSavvyWrapNot
            }
          >
            <div
              className={
                isClicked || isFriend
                  ? style.planetUserInfoContainer
                  : style.planetUserInfoContainerNot
              }
              onClick={() => setIsButtonOn(true)}
            >
              <div className={style.planetUserInfoImage}>
                <img
                  src={process.env.PUBLIC_URL + "/image/planetAvatar2.jpeg"}
                  alt="Avatar"
                />
              </div>
              <div className={style.planetUserInfoDetails}>
                <div className={style.planet1UserInfoName}>TechSavvyGal</div>
                {isClicked && (
                  <div className={style.planet2UserInfoId}>
                    @digitalexplorer.eth
                  </div>
                )}
              </div>
            </div>
            {isClicked && isButtonOn && (
              <button
                className={style.planetUserInfoButton}
                onClick={() => {
                  window.location.href = "/experience/friendprofile";
                }}
              >
                <GoToProfile />
                <div>Go to Profile</div>
              </button>
            )}
            <div className={style.planetFinger}>
              <FingerIcon />
            </div>
          </div>
        </div>
        <div className={style.planet} ref={planet3Ref}>
          <Planet3 />
        </div>
        <div className={style.planet} ref={planet4Ref}>
          <Planet4 />
        </div>
        <div className={style.planet} ref={planet5Ref}>
          <Planet5 />
        </div>
        <div className={style.planet} ref={planet6Ref}>
          <Planet6 />
        </div>
        <div className={style.planet} ref={planet7Ref}>
          <Planet7 />
        </div>
        <div className={style.planet} ref={planet8Ref}>
          <Planet8 />
        </div>
        <div className={style.planet} ref={planet9Ref}>
          <Planet9 />
        </div>
      </div>
      <div className={style.connectedInfo}>
        <div className={style.connectedInfoWrap}>
          <div className={style.connectedInfoHeaderWrap}>
            <div className={style.connectedInfoHeader}>connected with</div>
            <div className={style.connectedInfoHeaderDesc}>
              {isClicked ? "2,187 Planets" : "421 Planets"}
            </div>
          </div>
          <div className={style.connectedInfoBody}>
            {isClicked
              ? userSavvyConnectedData.map((item, index) => (
                  <div className={style.connectedInfoBodyItem} key={index}>
                    <div className={style.rank}>{item.rank}</div>
                    <div className={style.userRankInfo}>
                      <div className={style.userRankImg}>
                        <img
                          src={process.env.PUBLIC_URL + `${item.profileImage}`}
                          alt="profileImage"
                        />
                      </div>
                      <div className={style.userRankInfoDetails}>
                        <div className={style.userRankId}>{item.id}</div>
                        <div className={style.userRankPercent}>
                          {item.percent}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : userConnectedData.map((item, index) => (
                  <div className={style.connectedInfoBodyItem} key={index}>
                    <div className={style.rank}>{item.rank}</div>
                    <div className={style.userRankInfo}>
                      <div className={style.userRankImg}>
                        <img
                          src={process.env.PUBLIC_URL + `${item.profileImage}`}
                          alt="profileImage"
                        />
                      </div>
                      <div className={style.userRankInfoDetails}>
                        <div className={style.userRankId}>{item.id}</div>
                        <div className={style.userRankPercent}>
                          {item.percent}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Space;
