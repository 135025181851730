import React, { useEffect } from "react";
import KyHeader from "../kyHeader/KyHeader";
import style from "./style.module.scss";
import MarketCard from "../../../component/marketplaceCard/MarketCard";
import Avatar from "../../../component/avatar/Avatar";
import LeftRightArrowIcon from "../../../icon/leftRightArrow/LeftRightArrowIcon";
import ShowAllIcon from "../../../icon/showAll/ShowAllIcon";
import CategoryCard from "../../../component/categoryCard/CategoryCard";
import { DemoProps } from "../../../lib/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";
import { useNavigate } from "react-router-dom";

const Marketplace = (props: DemoProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  const isMarketData = [
    {
      image: "marketPlanet1",
      season: "Season 6",
      title: "Stardust Seraph",
      author: "bitBlur",
      profileImage: "marketProfile1",
    },
    {
      image: "marketPlanet2",
      season: "Season 1",
      title: "Stellarion Cascade",
      author: "artbyte",
      profileImage: "marketProfile2",
    },
    {
      image: "marketPlanet3",
      season: "Season 1",
      title: "Astridium Flux",
      author: "CryptoVisionary",
      profileImage: "marketProfile3",
    },
    {
      image: "marketPlanet4",
      season: "Season 2",
      title: "Lyra Cometium",
      author: "Cometrix",
      profileImage: "marketProfile4",
    },
    {
      image: "marketPlanet5",
      season: "Season 11",
      title: "Luminoir",
      author: "Lyra Melisma",
      profileImage: "marketProfile5",
    },
  ];

  const isCategoriesData = [
    {
      image: "marketCategories1",
      title: "Pointing Arrows",
      desc: "Mysterious and ethereal digital artworks that explore the enigma...",
      author: "Art Enchantress",
      profileImage: "marketCategoriesProfile1",
    },
    {
      image: "marketCategories2",
      title: "Mythic Mirage",
      desc: "Playful and mind-bending visual representations inspired by the ...",
      author: "Lyra Melisma",
      profileImage: "marketCategoriesProfile2",
    },
    {
      image: "marketCategories3",
      title: "Exclusive Pass",
      desc: "Captivating NFTs inspired by ancient myths and legends...",
      author: "Tech Virtuoso",
      profileImage: "marketCategoriesProfile3",
    },
    {
      image: "marketCategories4",
      title: "Field of Flowers",
      desc: "Digital artworks capturing the serendipitous beauty of nature...",
      author: "CryptoVisionary",
      profileImage: "marketCategoriesProfile4",
    },
    {
      image: "marketCategories5",
      title: "Celestial Symphony",
      desc: "A harmonious collection of celestial-inspired artworks...",
      author: "Nebulora",
      profileImage: "marketCategoriesProfile5",
    },
    {
      image: "marketCategories6",
      title: "Play",
      desc: "Delightful and whimsical digital illustrations that spark joy and...",
      author: "Nebulora",
      profileImage: "marketCategoriesProfile6",
    },
    {
      image: "marketCategories7",
      title: "Digital Biology",
      desc: "Futuristic and visionary artworks exploring the themes of ...",
      author: "Aurora Mythos",
      profileImage: "marketCategoriesProfile7",
    },
  ];

  const isAvatarData = [
    {
      name: "NFTGuru",
      title: "NFT Whales",
      image: "marketAvatar1",
    },
    {
      name: "NFTGuru",
      title: "NFT Whales",
      image: "marketAvatar2",
    },
    {
      name: "CryptoCanvas",
      title: "3nd NFT Creator",
      image: "marketAvatar3",
    },
    {
      name: "Collector X",
      title: "Virtual Art Collector",
      image: "marketAvatar4",
    },
    {
      name: "ArtByte",
      title: "KY Value Top 10",
      image: "marketAvatar5",
    },
    {
      name: "Luna Melody",
      title: "Singer-songwriter",
      image: "marketAvatar6",
    },
  ];
  return (
    <section>
      <div className={style.commonHeader}>
        <KyHeader prefix={props.prefix} isPoint="trends" />
      </div>
      <article className={style.marketplaceArticle}>
        <div className={style.marketplaceHeaderWrap}>
          <div className={style.marketplaceHeaderImage}>
            <img
              alt="marketplaceHeaderImage"
              src={
                process.env.PUBLIC_URL + "/image/marketplaceHeaderImage.jpeg"
              }
            />
          </div>
          <div className={style.marketplaceHeaderText}>
            <div className={style.marketplaceHeaderTextItem}>
              <div className={style.marketplaceHeaderTextItemTitleWrap}>
                <div className={style.marketplaceHeaderTextItemTitle}>
                  Discover
                </div>
                <div className={style.marketplaceHeaderTextItemTitle}>
                  the best collections & planets
                </div>
              </div>
              <div className={style.marketplaceHeaderTextItemDesc}>
                Discover the exquisite flavors of your favorite collections
                delivered directly to your digital gallery.
              </div>
            </div>
            <div className={style.marketplaceHeaderSearch}>
              <button className={style.marketplaceHeaderSearchBtn}>
                search
              </button>
            </div>
          </div>
        </div>
      </article>
      <article className={style.marketplaceBodyArticle}>
        <div className={style.marketplaceBody}>
          <div className={style.marketplaceNotabelPlanet}>
            <div className={style.marketplaceNotabelPlanetTitleWrap}>
              <div className={style.marketplaceNotabelPlanetTitle}>
                Notable Planets
              </div>
              <div className={style.marketplaceNotabelPlanetShowAll}>
                <LeftRightArrowIcon />
                <ShowAllIcon />
              </div>
            </div>
            <div className={style.marketplaceNotabelPlanetCardWrap}>
              {isMarketData.map((item, index) => (
                <MarketCard
                  key={index}
                  image={item.image}
                  season={item.season}
                  title={item.title}
                  author={item.author}
                  profileImage={item.profileImage}
                  onClick={() => {
                    if (index === 0) {
                      window.location.href = "/experience/planetinfo";
                    } else {
                      return;
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <div className={style.marketplaceTopWrap}>
            <div className={style.marketplaceTopTitleWrap}>
              <div className={style.marketplaceTop}>Top Celebrities</div>
              <ShowAllIcon />
            </div>
            <div className={style.marketplaceAvatarContainer}>
              {isAvatarData.map((item, index) => (
                <Avatar
                  key={index}
                  name={item.name}
                  title={item.title}
                  image={item.image}
                />
              ))}
            </div>
          </div>
          <div className={style.marketplaceCategories}>
            <div className={style.marketplaceCategoriesHeader}>
              <div className={style.marketplaceCategoriesHeaderTitle}>
                Explore Categories
              </div>
              <div className={style.marketplaceCategoriesHeaderItems}>
                <div className={style.marketplaceCategoriesHeaderButtons}>
                  <button
                    className={style.marketplaceCategoriesHeaderButtonItemFocus}
                  >
                    Recommended
                  </button>
                  <button
                    className={style.marketplaceCategoriesHeaderButtonItem}
                  >
                    Fashion
                  </button>
                  <button
                    className={style.marketplaceCategoriesHeaderButtonItem}
                  >
                    Art
                  </button>
                  <button
                    className={style.marketplaceCategoriesHeaderButtonItem}
                  >
                    Music
                  </button>
                  <button
                    className={style.marketplaceCategoriesHeaderButtonItem}
                  >
                    character
                  </button>
                </div>
                <div className={style.marketplaceCategoriesHeaderShowAll}>
                  <LeftRightArrowIcon />
                  <ShowAllIcon />
                </div>
              </div>
            </div>
            <div className={style.marketplaceCategoriesCardWrap}>
              {isCategoriesData.map((item, index) => (
                <CategoryCard
                  key={index}
                  image={item.image}
                  title={item.title}
                  desc={item.desc}
                  author={item.author}
                  profileImage={item.profileImage}
                />
              ))}
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Marketplace;
