import Lottie from "lottie-react";
import sunLottie from "../../../lottie/solarLottie.json";
import style from "./style.module.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../../store";

const BitBlueLock = () => {
  const isBitBluePassword = "bitblue1115!";
  const [isPassword, setIsPassword] = useState("");
  const [isPasswordMessage, setIsPasswordMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordCheck = () => {
    if (isPassword === isBitBluePassword) {
      dispatch(login());
      navigate("/");
    } else if (isPasswordMessage.length === 0) {
      setIsPasswordMessage("비밀번호를 입력해 주세요");
    } else if (isPasswordMessage !== isBitBluePassword) {
      setIsPasswordMessage("비밀번호가 일치하지 않습니다.");
    }
  };

  return (
    <section className={style.bitblueLockWrap}>
      <article className={style.inputArea}>
        <div className={style.inputWrap}>
          <div className={style.inputHeaderImage}>
            <img
              src={process.env.PUBLIC_URL + "./image/bitblue.png"}
              onClick={() => {
                window.location.href = "/";
              }}
            />
          </div>
          <div className={style.inputTitleWrap}>
            <div className={style.inputTitle}>Password required</div>
            <div className={style.inputDesc}>
              You need a password to access the <br />
              Know-Yourself prototype
            </div>
          </div>
          <div className={style.passwordInputWrap}>
            <input
              value={isPassword}
              placeholder="Enter password"
              type="password"
              className={style.passwordInput}
              onChange={(e) => setIsPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  passwordCheck();
                }
              }}
            />
            <div
              className={
                isPasswordMessage.length > 0
                  ? style.passwordMessage
                  : style.passwordMessageNone
              }
            >
              {isPasswordMessage.length > 0 && isPasswordMessage}
            </div>
          </div>
          <button className={style.continueBtn} onClick={() => passwordCheck()}>
            Continue
          </button>
          <div className={style.contactInfo}>
            contact with <a href="https://www.bitblue.team/">bitBlue</a>
          </div>
        </div>
      </article>
    </section>
  );
};

export default BitBlueLock;
