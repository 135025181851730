import { ReactComponent as FourArrow } from "./FourArrow.svg";

const FourArrowIcon = () => {
  return (
    <>
      <FourArrow />
    </>
  );
};

export default FourArrowIcon;
