import { ReactComponent as Twitter } from "./Twitter.svg";

const TwitterIcon = () => {
  return (
    <>
      <Twitter />
    </>
  );
};

export default TwitterIcon;
