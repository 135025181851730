import style from "./style.module.scss";
import { useEffect, useState } from "react";
import Step1 from "./kystep/step1/Step1";
import Step2 from "./kystep/step2/Step2";
import Step3 from "./kystep/step3/Step3";
import Step4 from "./kystep/step4/Step4";
import Step5 from "./kystep/step5/Step5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

const KyLanding = () => {
  const [isStep, setIsStep] = useState<number>(1);
  const isComponentSteps = [
    <Step1 setIsStep={setIsStep} />,
    <Step2 setIsStep={setIsStep} />,
    <Step3 setIsStep={setIsStep} />,
    <Step4 setIsStep={setIsStep} />,
    <Step5 setIsStep={setIsStep} />,
  ];

  const isStepRenderer = (isStep: number) => {
    switch (isStep) {
      case 1:
        return isComponentSteps[isStep - 1];
      case 2:
        return isComponentSteps[isStep - 1];
      case 3:
        return isComponentSteps[isStep - 1];
      case 4:
        return isComponentSteps[isStep - 1];
      case 5:
        return isComponentSteps[isStep - 1];

      default:
        return isComponentSteps[isStep - 1];
    }
  };
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  return (
    <>
      <section className={style.kyDemoLanding}>
        {isStepRenderer(isStep)}
      </section>
    </>
  );
};

export default KyLanding;
