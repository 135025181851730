import { ReactComponent as GoToProfile } from "./GotoProfile.svg";

const GoToProfileIcon = () => {
  return (
    <>
      <GoToProfile />
    </>
  );
};

export default GoToProfileIcon;
