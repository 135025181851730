import { ReactComponent as Poly } from "./Poly.svg";

const PolyIcon = () => {
  return (
    <>
      <Poly />
    </>
  );
};

export default PolyIcon;
