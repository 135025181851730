import style from "./style.module.scss";
import KyHeader from "../kyHeader/KyHeader";
import ProfileSetting from "../../../icon/profileSetting/ProfileSetting";
import ProfileDot from "../../../icon/profileDot/ProfileDot";
import ChatProfile from "../../../icon/chatProfile/ChatProfile";
import ChatRoomIcon from "../../../icon/chatreoomIcon/ChatroomIcon";
import DotDotDotIcon from "../../../icon/dotdotdot/DotDotDot";
import { useEffect, useState } from "react";
import { DemoProps } from "../../../lib/types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

const KyChat = (props: DemoProps) => {
  const [isChat1Displayed, setIsChat1Displayed] = useState(true);
  const [isChat2Displayed, setIsChat2Displayed] = useState(true);
  const [isChat3Displayed, setIsChat3Displayed] = useState(true);
  const [isChat4Displayed, setIsChat4Displayed] = useState(true);
  const [isChat5Displayed, setIsChat5Displayed] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsChat1Displayed(false);
    }, 1000);
    setTimeout(() => {
      setIsChat2Displayed(false);
    }, 3000);
    setTimeout(() => {
      setIsChat3Displayed(false);
    }, 5000);
    setTimeout(() => {
      setIsChat4Displayed(false);
    }, 6000);
    setTimeout(() => {
      setIsChat5Displayed(false);
    }, 7500);
  }, []);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  return (
    <section className={style.kyChatSectionSection}>
      <KyHeader prefix={props.prefix} isPoint="market" />
      <div className={style.kyChatSectionWrap}>
        <div className={style.kyChatSectionChatRoom}>
          <div className={style.kyChatSectionChatTitleWrap}>
            <div className={style.kyChatSectionChatTitle}>Chat</div>
            <div className={style.kyChatSectionChatPublicPrivateTab}>
              <div className={style.kyChatSectionChatPublic}>Public</div>
              <div className={style.kyChatSectionChatPrivate}>Private</div>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "/image/chatList.jpeg"} />
            </div>
          </div>
        </div>
        <div className={style.kyChatSectionChatInput}>
          <div className={style.kyChatSectionChatInputHeader}>
            <div className={style.kyChatSectionChatInputHeaderWrap}>
              <div className={style.kyChatSectionChatInputHeaderIcon}>
                <ChatRoomIcon />
                <div>TechSavvyGal</div>
              </div>
              <DotDotDotIcon />
            </div>
          </div>
          <div className={style.kyChatSectionChatInputBody}>
            <div className={style.kyChatSectionChatInputBodyDay1}>2 May</div>
            <div className={style.kyChatSectionChatInputBodyChat1}>
              <div className={style.kyChatSectionChatInputBodyTextContainer}>
                <div
                  className={
                    isChat1Displayed
                      ? style.kyChatSectionChatInputBodyTextWrapDisabled
                      : style.kyChatSectionChatInputBodyTextWrap
                  }
                >
                  <div className={style.kyChatSectionChatInputBodyText}>
                    Hey TechSavvyGal!👋
                  </div>
                </div>
                <div
                  className={
                    isChat2Displayed
                      ? style.kyChatSectionChatInputBodyTextWrap2Disabled
                      : style.kyChatSectionChatInputBodyTextWrap2
                  }
                >
                  <div className={style.kyChatSectionChatInputBodyText}>
                    Have you heard any buzz about NFTs merging with virtual
                    reality? 🤩🌐🚀
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                isChat1Displayed
                  ? style.kyChatSectionChatInputBodyTimeDisabled
                  : style.kyChatSectionChatInputBodyTime
              }
            >
              4:38 PM
            </div>
            <div className={style.kyChatSectionChatInputBodyChat2}>
              <div
                className={
                  isChat3Displayed
                    ? style.kyChatSectionChatInputBodyUserIconDisabled
                    : style.kyChatSectionChatInputBodyUserIcon
                }
              >
                <ChatRoomIcon />
              </div>
              <div className={style.kyChatSectionChatInputBody2TextContainer}>
                <div
                  className={
                    isChat3Displayed
                      ? style.kyChatSectionChatInputBody2NameDisabled
                      : style.kyChatSectionChatInputBody2Name
                  }
                >
                  TechSavvyGal
                </div>
                <div className={style.kyChatSectionChatInputBody2Chat}>
                  <div
                    className={
                      isChat3Displayed
                        ? style.kyChatSectionChatInputBodyChatMessage1Disabled
                        : style.kyChatSectionChatInputBodyChatMessage1
                    }
                  >
                    <div
                      className={
                        style.kyChatSectionChatInputBodyChatMessage1Text
                      }
                    >
                      Absolutely! There's this new project called "VRVerse" that
                      aims to create a metaverse where NFTs can be displayed and
                      experienced in VR environments.
                    </div>
                  </div>
                  <div
                    className={
                      isChat4Displayed
                        ? style.kyChatSectionChatInputBodyChatMessage2Disabled
                        : style.kyChatSectionChatInputBodyChatMessage2
                    }
                  >
                    <div
                      className={
                        style.kyChatSectionChatInputBodyChatMessage2Text
                      }
                    >
                      It's an exciting project with massive potential to
                      transform the way we experience and interact with digital
                      art. 🌈🔮🖼️
                      <a>
                        https://www.crypto
                        <br />
                        voxels.com/gallery
                      </a>
                    </div>
                  </div>
                  <div
                    className={
                      isChat4Displayed
                        ? style.kyChatSectionChatInputBodyChatMessage3Disabled
                        : style.yChatSectionChatInputBodyChatMessage3
                    }
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/image/chatLink.jpeg"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                isChat3Displayed
                  ? style.kyChatSectionChatInputBody2TimeDisabled
                  : style.kyChatSectionChatInputBody2Time
              }
            >
              4:38 PM
            </div>
            <div
              className={
                isChat5Displayed
                  ? style.kyChatSectionChatInputBodyDay2Disabled
                  : style.kyChatSectionChatInputBodyDay2
              }
            >
              3 May
            </div>
            <div
              className={
                isChat5Displayed
                  ? style.kyChatSectionChatInputBodyChat3Disabled
                  : style.kyChatSectionChatInputBodyChat3
              }
            >
              <div className={style.kyChatSectionChatInputBody3Text}>
                <div className={style.kyChatSectionChatInputBody3TextWrap}>
                  Thanks for sharing the link! I'll definitely dive deeper into
                  VRVerse and keep an eye on their progress.
                </div>
              </div>
            </div>
            <div
              className={
                isChat5Displayed
                  ? style.kyChatSectionChatInputBody3TimeDisabled
                  : style.kyChatSectionChatInputBody3Time
              }
            >
              12:38 PM
            </div>
          </div>
          <div className={style.kyChatSectionChatInputBodyInput}>
            <img src={process.env.PUBLIC_URL + "/image/textfield.jpeg"} />
          </div>
        </div>
        <div className={style.kyChatSectionChatUser}>
          <div className={style.kyChatSectionChatUserHeader}>
            <div className={style.kyChatSectionChatUserInfoIcon}>
              <ProfileSetting />
              <ProfileDot />
            </div>
            <div className={style.kyChatSectionChatUserInfo}>
              <div className={style.kyChatSectionChatUserProfile}>
                <ChatProfile />
              </div>
              <div className={style.kyChatSectionChatUserInfoText}>
                <div className={style.kyChatSectionChatUserTextWrap}>
                  <div className={style.kyChatSectionChatUserName}>
                    TechSavvyGal
                  </div>
                  <div className={style.kyChatSectionChatUserId}>
                    @digitalexplorer.eth
                  </div>
                </div>
                <div className={style.kyChatSectionChatUserGoToBtnWrap}>
                  <button
                    className={style.kyChatSectionChatUserGoToBtn}
                    onClick={() => {
                      window.location.href = "/experience/friendprofile";
                    }}
                  >
                    Go to Profile Page
                  </button>
                </div>
              </div>
              <div className={style.kyChatSectionChatUserContent}>
                <img src={process.env.PUBLIC_URL + "/image/chatMedia.jpeg"} />
                <img src={process.env.PUBLIC_URL + "/image/chatLinks.jpeg"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KyChat;
