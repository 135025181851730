import { ReactComponent as Message } from "./Message.svg";

const MessageIcon = () => {
  return (
    <>
      <Message />
    </>
  );
};

export default MessageIcon;
