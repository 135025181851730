import { ReactComponent as Logo } from "./mobileLogo.svg";



const MobileLogo = () => {
    return (
        <div>
            <Logo />
        </div>
    );
};

export default MobileLogo;