import { ReactComponent as SpacePlanet4 } from "./spacePlanet4.svg";

const Planet4 = () => {
  return (
    <>
      <SpacePlanet4 />
    </>
  );
};

export default Planet4;
