import { createStore } from "redux";

export const SET_CURRENT_LOCALE = "SET_CURRENT_LOCALE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_LAST_ACTIVITY_TIME = "SET_LAST_ACTIVITY_TIME";

interface SetCurrentLocaleAction {
  type: typeof SET_CURRENT_LOCALE;
  payload: string;
}

interface LoginAction {
  type: typeof LOGIN;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface SetLastActivityTimeAction {
  type: typeof SET_LAST_ACTIVITY_TIME;
  payload: number;
}

export const setCurrentLocale = (locale: string): SetCurrentLocaleAction => ({
  type: SET_CURRENT_LOCALE,
  payload: locale,
});

export const login = (): LoginAction => ({
  type: LOGIN,
});

export const logout = (): LogoutAction => ({
  type: LOGOUT,
});

export const setLastActivityTime = (): SetLastActivityTimeAction => ({
  type: SET_LAST_ACTIVITY_TIME,
  payload: Date.now(),
});

export interface State {
  currentLocale: string;
  isLoggedIn: boolean;
  lastActivityTime: number | null;
}

export const initialState: State = {
  currentLocale: "en",
  isLoggedIn: false,
  lastActivityTime: null,
};

type Action =
  | SetCurrentLocaleAction
  | LoginAction
  | LogoutAction
  | SetLastActivityTimeAction;

const idleTimeout = 10 * 60 * 1000;

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_CURRENT_LOCALE:
      return {
        ...state,
        currentLocale: action.payload,
      };

    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };

    case SET_LAST_ACTIVITY_TIME:
      return {
        ...state,
        lastActivityTime: action.payload,
      };

    default:
      return state;
  }
};

const saveState = (state: State) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (err) {
    console.log("STORE ERR:", err);
  }
};

const loadState = (): State | undefined => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const persistedState = loadState();
const store = createStore(reducer, persistedState);

store.subscribe(() => {
  saveState(store.getState());
});

// store.dispatch(login());
store.dispatch(setLastActivityTime());

setInterval(() => {
  const state = store.getState();
  const currentTime = Date.now();
  const elapsedTime = currentTime - (state.lastActivityTime || 0);
  if (state.isLoggedIn && elapsedTime >= idleTimeout) {
    store.dispatch(logout());
  }
}, 10 * 60 * 1000); // 10분 (단위: 밀리초)

export default store;
