import { ReactComponent as Avax } from "./Avax.svg";

const AvaxIcon = () => {
  return (
    <>
      <Avax />
    </>
  );
};

export default AvaxIcon;
