import style from "./style.module.scss";
import RightDropIcon from "../../icon/rightDrop/RightDrop";
import ProfileIcon from "../../icon/ProfileIcon/ProfileIcon";
import SettingIcon from "../../icon/settingIcon/Stting";
import OutIcon from "../../icon/Out/Out";
import React from "react";
import PencilIcon from "../../icon/pencil/PencilIcon";

interface ProfileDropDownProps {
  isOpen: boolean;
}

const ProfileDropDeown = (props: ProfileDropDownProps) => {
  return (
    <div
      className={
        props.isOpen ? style.profileDropDown : style.profileDropDownClose
      }
    >
      <div className={style.profileDropDownWrap}>
        <div
          className={style.profileDropDownContainer}
          onClick={() => {
            window.location.href = "/experience/mypage";
          }}
        >
          <div className={style.profileDropDownIcon}>
            <ProfileIcon />
          </div>
          <div className={style.profileDropDownText}>
            <div className={style.profileDropDownName}>MyPage</div>
            <div className={style.profileDropDownRightIcon}>
              <RightDropIcon />
            </div>
          </div>
        </div>
        <div className={style.profileDropDownContainer}>
          <div className={style.profileDropDownIcon}>
            <PencilIcon />
          </div>
          <div className={style.profileDropDownText}>
            <div className={style.profileDropDownName}>Create</div>
            <div className={style.profileDropDownRightIcon}>
              <RightDropIcon />
            </div>
          </div>
        </div>
        <div className={style.profileDropDownContainer}>
          <div className={style.profileDropDownIcon}>
            <SettingIcon />
          </div>
          <div className={style.profileDropDownText}>
            <div className={style.profileDropDownName}>Account Settings</div>
            <div className={style.profileDropDownRightIcon}>
              <RightDropIcon />
            </div>
          </div>
        </div>
        <div className={style.profileDropDownContainer}>
          <div className={style.profileDropDownIcon}>
            <OutIcon />
          </div>
          <div className={style.profileDropDownText}>
            <div className={style.profileDropDownName}>DisconnectWallet</div>
            <div className={style.profileDropDownRightIcon}>
              <RightDropIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropDeown;
