import { ReactComponent as ProfileSetting } from "./ProfileSetting.svg";

const ProfileSettingIcon = () => {
  return (
    <>
      <ProfileSetting />
    </>
  );
};

export default ProfileSettingIcon;
