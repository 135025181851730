import { ReactComponent as Apple } from "./Apple.svg";

const AppleIcon = () => {
  return (
    <>
      <Apple />
    </>
  );
};

export default AppleIcon;
