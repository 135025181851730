import { ReactComponent as Close } from "./close.svg";



const CloseIcon = () => {
    return (
        <div>
            <Close />
        </div>
    );
};

export default CloseIcon;