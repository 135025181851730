import Locale from "../../../component/locale/Locale";
import { useCurrentLocale } from "../../../lib/function";
import "./style.scss";
import Modal from "../../../component/modal/Modal";
import HardModal from "../../../component/hardModal/HardModal";
import {
  image1Url,
  image2Url,
  image3Url,
  image4Url,
  image5Url,
  image6Url,
  image7Url,
  image8Url,
  image9Url,
} from "../../../lib/image";
export type LandingProps = {
  isLandingActiveHandler: () => string;
  isKYActiveHandler: () => string;
  isStyleChange: () => string;
  isPtagStyleChange: () => string;
  isByActiveHandler: () => string;

  isSelected: string;
  isImageState: string;
};
const WebLanding = (props: LandingProps) => {
  const currentLocale = useCurrentLocale();

  return (
    <>
      <article className="landingArticle">
        <div className={props.isLandingActiveHandler()}>
          <p className="landingTextP">FIND YOUR FAN AND GROW TOGETHER</p>
          <h1 className="landingTextH1">WEB3 SPACE</h1>
          <h1 className="landingTextH1">FOR YOURSELF</h1>
          <div className="landingTextLast">Meet you soon !</div>
        </div>
        <div className={props.isKYActiveHandler()}>
          <div className={props.isStyleChange()}>
            <div className="kyHeaderItem">
              <Locale tag="ky_landing_header1" />
            </div>
            <div className="kyHeaderItem2">
              <div>
                <Locale tag="ky_landing_ky" />
              </div>
              <div>
                <Locale tag="ky_landing_header2" />
              </div>
            </div>
            <div className="kyHeaderItem">
              <Locale tag="ky_landing_header3" />
            </div>
          </div>
          <div className={props.isPtagStyleChange()}>
            <div className="kyPItem">
              <div>
                <Locale tag="ky_landing_p1" />
              </div>
              <div>
                <Locale tag="ky_landing_p2" />
              </div>
            </div>
            <div className="kyPItem">
              <div>
                <Locale tag="ky_landing_p3" />
              </div>
              <div>
                <Locale tag="ky_landing_p4" />
              </div>
            </div>
            <div className="kyPItem">
              <div>
                <Locale tag="ky_landing_p5" />
              </div>
              <div>
                <Locale tag="ky_landing_p6" />
              </div>
            </div>
          </div>
        </div>
        <div className={props.isByActiveHandler()}>
          <div
            className={
              currentLocale === "en" ? "byHeaderWrap" : "byHeaderWrapKo"
            }
          >
            <div>
              <Locale tag="ky_by_h1" />
            </div>
            <div>
              <Locale tag="ky_by_h2" />
            </div>
          </div>
          <div className={currentLocale === "en" ? "byPHeader" : "byPHeaderKo"}>
            <div>
              <Locale tag="ky_by_p1" />
            </div>
            <div>{currentLocale === "en" && <Locale tag="ky_by_p2" />}</div>
          </div>
          <div className={currentLocale === "en" ? "byList" : "byListKo"}>
            <div>
              <Locale tag="ky_by_p3" />
            </div>
            <div>
              <Locale tag="ky_by_p4" />
            </div>
            <div>
              <Locale tag="ky_by_p5" />
            </div>
            <div>
              <Locale tag="ky_by_p6" />
            </div>
            <div>
              <Locale tag="ky_by_p7" />
            </div>
            <div>
              <Locale tag="ky_by_p8" />
            </div>
          </div>
        </div>
        {props.isSelected === "by" ? (
          <div className="byImage">
            <img
              src={process.env.PUBLIC_URL + "/image/byBack.jpeg"}
              alt="byBck"
            />
            <img
              className={props.isImageState}
              src={process.env.PUBLIC_URL + "/image/character2.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet7.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet9.jpeg"}
              alt="mainImage"
            />
          </div>
        ) : (
          <div className="mainCharacter">
            <img
              src={process.env.PUBLIC_URL + "/image/mainPlanetBack.jpeg"}
              alt="main character"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/character1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet1.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet2.jpeg"}
              alt="mainImage"
            />
            <img
              src={process.env.PUBLIC_URL + "/image/planet4.jpeg"}
              alt="mainImage"
            />
          </div>
        )}
      </article>
    </>
  );
};

export default WebLanding;
