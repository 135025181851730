import { ReactComponent as Setting } from "./Setting.svg";

const SettingIcon = () => {
  return (
    <>
      <Setting />
    </>
  );
};

export default SettingIcon;
