import SilverPlanetImage from "../../../../../icon/silverPlanet/SilverPlanet";
import style from "./style.module.scss";
import ColorPicker from "../../../../../icon/colorPicker/ColorPicker";
import { useState } from "react";
import Modal from "../../../../../component/modal/Modal";
import PolygonIcon from "../../../../../icon/polygon/PolygonIcon";
import CompleteIcon from "../../../../../icon/complete/CompleteIcon";

import Lottie from "lottie-react";
import Loader from "../../../../../lottie/planetLoader.json";
import { StepProps } from "../step1/Step1";
import Planet1 from "../../../../../icon/mainPlanet/MainPlanet";
import FingerIcon from "../../../../../icon/finger/Finger";
import FingerLeftIcon from "../../../../../icon/fingerLeft/fingerLeft";

const Step2 = (props: StepProps) => {
  const [isColorValue, setIsColorValue] = useState("Color");
  const isColored = isColorValue === "A7B5FF";
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fingerStep, setFingerStep] = useState(1);
  const isCompleteModal = isLoading ? (
    <div className={style.loaderWrap}>
      <div className={style.lottieWrap}>
        <Lottie animationData={Loader} />
      </div>
      <div>Loading ...</div>
    </div>
  ) : (
    <div className={style.completeModalContainer}>
      <div className={style.completeHeaderWrap}>
        <div>
          <CompleteIcon />
        </div>
        <div>Complete!</div>
      </div>
      <div className={style.bodyWrap}>
        <div className={style.txWrap1}>
          <div className={style.txItem1}>Tx Hash</div>
          <div className={style.txItem2}>9C765C...3C5EDC</div>
        </div>
        <div className={style.txWrap2}>
          <div className={style.txItem1}>Tx Fee</div>
          <div className={style.txItem2}>0 MATIC</div>
        </div>
      </div>
    </div>
  );

  const isConfirmModal = (
    <div className={style.confirmModalContainer}>
      <div className={style.modalTextWrap}>
        <div className={style.modalHeader}>
          <div className={style.modalTitle}>Would you like to create</div>
          <div className={style.modalTitle}>your own Planet?</div>
        </div>
        <div className={style.modalDescWrap}>
          <div className={style.modalDescription}>
            Each transaction will incur non-
          </div>
          <div className={style.modalDescription}>
            refundable gas fees. You can't undo this
          </div>
          <div className={style.modalDescription}>action later.</div>
        </div>
      </div>
      <div className={style.chainInfoWrap}>
        <div className={style.chainInfoContainer}>
          <div className={style.polygonIcon}>
            <PolygonIcon />
            <div className={style.polygonInfoWrap}>
              <div>0x9876...4321ba</div>
              <div>Polygon</div>
            </div>
          </div>
          <button className={style.connectedButton}>Connected</button>
        </div>
      </div>
      <div className={style.priceInfoWrap}>
        <div className={style.priceInfoContainer}>
          <div className={style.infoItem1}>Price</div>
          <div className={style.infoItem2}>0 MATIC</div>
        </div>
        <div className={style.feeInfoContainer}>
          <div className={style.infoItem1}>Fee</div>
          <div className={style.infoItem2}>0%</div>
        </div>
        <div className={style.paymentInfoContainer}>
          <div className={style.infoItem1}>You Will Pay</div>
          <div>0</div>
        </div>
      </div>
      <div className={style.modalBtnWrap}>
        <button
          className={style.cancelBtn}
          onClick={() => setIsConfirmModalOpen(false)}
        >
          Cancel
        </button>
        <button
          className={style.confirmBtn}
          onClick={() => {
            setIsConfirmModalOpen(false);
            setIsLoading(true);
            setIsCompleteModalOpen(true);
            setTimeout(() => {
              setIsLoading(false);
            }, 3000);
            setTimeout(() => {
              setIsCompleteModalOpen(false);
            }, 6000);
            setTimeout(() => {
              props.setIsStep(3);
            }, 7000);
          }}
        >
          Confirm
        </button>
      </div>
      <div className={style.finger3Wrap}>
        {fingerStep === 3 ? <FingerLeftIcon transform="rotate(270)" /> : <></>}
      </div>
    </div>
  );

  return (
    <article className={style.step2ArticleWrap}>
      <div className={style.step2PlanetWrap}>
        <div className={style.mintPlanet}>Mint My Planet</div>
        <div
          className={
            isColored ? style.colorPlanet : style.silverPlanetComponent
          }
        >
          {isColorValue === "A7B5FF" ? (
            <Planet1 width="480" height="480" />
          ) : (
            <SilverPlanetImage />
          )}
          <div className={style.planetName}>bitBlur</div>
          <div className={style.planetColor}>#13728121</div>
        </div>
      </div>
      <div className={style.planetDescWrap}>
        <div className={style.planetDescHeader}>Select Color</div>
        <div className={style.planetDesc}>
          <p>
            This is your planet. It represents and trades individual values
            formed in numerous relationships through on-chain.
          </p>
          <p>
            51% of the planet’s surface will be colored in a selected color by
            you, and the rest of the surface will be colored by other users who
            interact with you.
          </p>
        </div>
        <div className={style.colorPickerWrap}>
          <div className={style.colorPicker}>
            <div className={style.colorText}>Color</div>
            <div
              className={fingerStep === 2 ? style.inputWrap2 : style.inputWrap}
              onClick={() => {
                setIsColorValue("A7B5FF");
                setFingerStep(2);
              }}
            >
              <div className={style.inputSharp}>#</div>
              <input
                className={style.colorInput}
                value={isColorValue}
                disabled={true}
              />
            </div>
          </div>
          <div className={style.colorPickerIcon}>
            <ColorPicker />
          </div>
        </div>
        <div className={style.fingerWrap}>
          {fingerStep === 1 ? <FingerIcon transform="rotate(0)" /> : <></>}
        </div>
      </div>
      <div className={style.btnFingerWrap}>
        <button
          className={isColored ? style.nextBtn : style.nextBtnDisabled}
          disabled={!isColored}
          onClick={() => {
            setIsConfirmModalOpen(true);
            setFingerStep(3);
          }}
        >
          Next
        </button>
        <div className={style.finger2Wrap}>
          {fingerStep === 2 ? <FingerIcon transform="rotate(0)" /> : <></>}
        </div>
      </div>
      <Modal children={isConfirmModal} isModalOpen={isConfirmModalOpen} />
      <Modal children={isCompleteModal} isModalOpen={isCompleteModalOpen} />
    </article>
  );
};

export default Step2;
