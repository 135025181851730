import React from "react";
import KyHeader from "../kyHeader/KyHeader";
import style from "./style.module.scss";
import TwitterIcon from "../../../icon/twitter/TwitterIcon";
import FacebookIcon from "../../../icon/facebook/FacebookIcon";
import InstagramIcon from "../../../icon/instagram/InstagramIcon";
import AppleIcon from "../../../icon/apple/Apple";
import GithubIcon from "../../../icon/github/Github";
import { DemoProps } from "../../../lib/types";
import FingerIcon from "../../../icon/finger/Finger";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";
import { useNavigate } from "react-router-dom";

const KyPlanetInfo = (props: DemoProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  return (
    <section>
      <KyHeader prefix={props.prefix} />
      <div className={style.kyPlanetInfoBanner}></div>
      <div className={style.kyPlanetInfoWrap}>
        <div className={style.kyPlanetInfoContainer}>
          <div className={style.kyPlanetInfoPlanetProfile}>
            <img
              src={process.env.PUBLIC_URL + "/image/planetInfoPlanet.jpeg"}
            />
          </div>
          <div className={style.kyPlanetInfoPlanetInfoHeader}>
            <div className={style.kyPlanetInfoPlanetInfoTitle}>
              Stardust Seraph
            </div>
            <div className={style.kyPlanetInfoPlanetInfoSns}>
              <TwitterIcon />
              <FacebookIcon />
              <InstagramIcon />
              <AppleIcon />
              <GithubIcon />
            </div>
          </div>
          <div className={style.kyPlanetInfoUserInfo}>
            <div
              className={style.kyPlanetInfoUserInfoDetails}
              onClick={() => {
                window.location.href = "/experience/mypage";
              }}
            >
              <div className={style.kyPlanetInfoUserInfoProfile}>
                <img
                  src={process.env.PUBLIC_URL + "/image/myPageAvatar.jpeg"}
                />
              </div>
              <div className={style.ktPlanetInfoUserInfoName}>bitblur</div>
            </div>
            <div className={style.kyPlanetInfoUserInfoDesc}>
              Loves exploring the latest trends in digital art and technology.
              <br />
              Always on the lookout for new and exciting opportunities to expand
              his knowledge and expertise in the digital world.
            </div>
            <div className={style.kyPlanetInfoUserInfoFinger}>
              <FingerIcon />
            </div>
          </div>
          <div className={style.kyPlanetInfoUserInfoWalletInfo}>
            <div className={style.kyPlanetInfoUserInfoWalletInfoDetails}>
              <div className={style.kyPlanetInfoUserInfoWalletInfoValue}>
                29,812 MATIC
              </div>
              <div className={style.kyPlanetInfoUserInfoWalletInfoTitle}>
                total volume
              </div>
            </div>
            <div className={style.kyPlanetInfoUserInfoWalletInfoDetails}>
              <div className={style.kyPlanetInfoUserInfoWalletInfoValue}>
                73 MATIC
              </div>
              <div className={style.kyPlanetInfoUserInfoWalletInfoTitle}>
                floor price
              </div>
            </div>
            <div className={style.kyPlanetInfoUserInfoWalletInfoDetails}>
              <div className={style.kyPlanetInfoUserInfoWalletInfoValue}>
                72 MATIC
              </div>
              <div className={style.kyPlanetInfoUserInfoWalletInfoTitle}>
                best offer
              </div>
            </div>
            <div className={style.kyPlanetInfoUserInfoWalletInfoDetails}>
              <div className={style.kyPlanetInfoUserInfoWalletInfoValue}>
                0.8%
              </div>
              <div className={style.kyPlanetInfoUserInfoWalletInfoTitle}>
                listed
              </div>
            </div>
            <div className={style.kyPlanetInfoUserInfoWalletInfoDetails}>
              <div className={style.kyPlanetInfoUserInfoWalletInfoValue}>
                2,331
              </div>
              <div className={style.kyPlanetInfoUserInfoWalletInfoTitle}>
                owners
              </div>
            </div>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + "/image/planetInfoItems.jpeg"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default KyPlanetInfo;
