import React, { useEffect, useState } from "react";
import "./App.css";
import Locale from "./component/locale/Locale";
import Header from "./component/header/Header";
import LandingPage from "./page/landing/LandingPage";
import {
  Breakpoints,
  BreakpointsPrefix,
  BreakpointsSize,
} from "./component/breakpoints/BreakPoints";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import KyDemo from "./page/kyDemo";
import NotFoundPage from "./page/notFoundPage/NotFoundPage";
import Marketplace from "./page/kyDemo/kyMarketPlace/Marketplace";
import Space from "./page/kyDemo/openSpace/Space";
import KyWorld from "./page/kyDemo/kyWorld/KyWorld";
import KyTrends from "./page/kyDemo/kyTrends/KyTrends";
import KyChat from "./page/kyDemo/kyChat/KyChat";
import FriendPage from "./page/kyFriendPage/FriendPage";
import MyPage from "./page/kyDemo/kyMyPage/MyPage";
import KyPlanetInfo from "./page/kyDemo/kyPlanetInfo/KyPlanetInfo";
import BitBlueLock from "./page/kyDemo/bitblueLock/BitblueLock";
import { useDispatch, useSelector } from "react-redux";
import { State as ReduxState } from "./store";

export type RootState = ReduxState;

function App() {
  const [isSelected, setIsSelected] = useState("");
  const [prefix, setPrefix] = useState<BreakpointsPrefix | undefined>();
  const [breakpointUnsubscribe, setBreakpointUnsubscribe] =
    useState<VoidFunction | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);

  useEffect(() => {
    const unsubscribe = Breakpoints.Subscribe(
      (prefix: string, size: number) => {
        if (size > BreakpointsSize.xl) {
          setPrefix("xxl");
        } else if (size > BreakpointsSize.md) {
          setPrefix("xl");
        } else if (size > BreakpointsSize.sm) {
          setPrefix("md");
        } else if (size < BreakpointsSize.md) {
          setPrefix("sm");
        }
      },
      true
    );

    setBreakpointUnsubscribe(() => unsubscribe);

    return () => {
      if (breakpointUnsubscribe) {
        breakpointUnsubscribe();
      }
    };
  }, []);
  const dispatch = useDispatch();

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage
                setIsSelected={setIsSelected}
                isSelected={isSelected}
                prefix={prefix}
              />
            }
          />
          <Route path="/bitbluelock" element={<BitBlueLock />} />
          <>
            <Route path="/experience" element={<KyDemo prefix={prefix} />} />
            <Route
              path="/experience/marketplace"
              element={<Marketplace prefix={prefix} />}
            />
            <Route
              path="/experience/profile/openspace"
              element={<Space prefix={prefix} />}
            />
            <Route
              path="/experience/world"
              element={<KyWorld prefix={prefix} />}
            />
            <Route
              path="/experience/trends"
              element={<KyTrends prefix={prefix} />}
            />
            <Route
              path="/experience/chat"
              element={<KyChat prefix={prefix} />}
            />
            <Route
              path="/experience/mypage"
              element={<MyPage prefix={prefix} />}
            />
            <Route
              path="/experience/planetinfo"
              element={<KyPlanetInfo prefix={prefix} />}
            />
            <Route
              path="/experience/friendprofile"
              element={<FriendPage prefix={prefix} />}
            />
          </>
          )
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
