import { ReactComponent as ColorPicker } from "./ColorPicker.svg";

const ColorPickerIcon = () => {
  return (
    <>
      <ColorPicker />
    </>
  );
};

export default ColorPickerIcon;
