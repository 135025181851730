import { ReactComponent as Blink } from "./Blink.svg";

const BlinkIcon = () => {
  return (
    <>
      <Blink />
    </>
  );
};

export default BlinkIcon;
