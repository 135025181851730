import style from "./style.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import KyHeader from "../kyDemo/kyHeader/KyHeader";
import LockIcon from "../../icon/lock/LockIcon";
import EthIcon from "../../icon/Eth/Eth";
import PolyIcon from "../../icon/poly/Poly";
import AvaxIcon from "../../icon/avax/Avax";
import UsaIcon from "../../icon/usa/Usa";
import TwitterIcon from "../../icon/twitter/TwitterIcon";
import FacebookIcon from "../../icon/facebook/FacebookIcon";
import InstagramIcon from "../../icon/instagram/InstagramIcon";
import AppleIcon from "../../icon/apple/Apple";
import GithubIcon from "../../icon/github/Github";
import ThunderIcon from "../../icon/thunbder/thunder";
import HeartIcon from "../../icon/heart/Heart";
import StarIcon from "../../icon/star/Star";
import BlinkIcon from "../../icon/blink/Blink";
import FourArrowIcon from "../../icon/fourArrow/FourArrow";
import { DemoProps } from "../../lib/types";
import FingerIcon from "../../icon/finger/Finger";
import { useSelector } from "react-redux";
import { RootState } from "../../App";

const FriendPage = (props: DemoProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const history = useNavigate();

  if (!isLoggedIn) {
    history("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  return (
    <section className={style.kyMyPageSection}>
      <KyHeader prefix={props.prefix} />
      <div
        className={style.kyMyPageBanner}
        onClick={() =>
          history("/experience/profile/openspace", {
            state: {
              from: "friend",
            },
          })
        }
      >
        <div className={style.kyMyPageBannerButtonWrap}>
          <button
            className={style.kyMyPageBannerButton}
            onClick={() =>
              history("/experience/profile/openspace", {
                state: {
                  from: "friend",
                },
              })
            }
          >
            Open Map
            <FourArrowIcon />
          </button>
        </div>
      </div>
      <div className={style.kyMyPageContent}>
        <div className={style.kyMyPageContentWrap}>
          <div className={style.kyMyPageContentLeft}>
            <div className={style.kyMyPageContentUserProfile}>
              <img src={process.env.PUBLIC_URL + "/image/myPageProfile.jpeg"} />
            </div>
            <div className={style.kyMyPageContentUserInfo}>
              <div className={style.kyMyPageContentUserInfoDetails}>
                <div className={style.kyMyPageContentUserInfoName}>
                  TechSavvyGal
                </div>
                <div className={style.kyMyPageContentUserInfoId}>
                  @digitalexplorer.eth
                </div>
              </div>
              <div className={style.kyMyPageContentUserInfoPrivateButtonWrap}>
                <button
                  className={style.kyMyPageContentUserInfoPrivateButton}
                  onClick={() => {
                    window.location.href = "/experience/chat";
                  }}
                >
                  <LockIcon />
                  <div>Private Message</div>
                </button>
                <div className={style.privateFinger}>
                  <FingerIcon />
                </div>
              </div>
            </div>
            <div className={style.bioContainer}>
              <div className={style.bioTitle}>Bio</div>
              <div className={style.bioContent}>
                <div className={style.bioContentText1}>
                  I'm an influencer and enthusiast of all things digital, from
                  exploring new technologies to discovering unique digital art
                  pieces. With my passion for innovation and creativity, I
                  strive to inspire others to embrace the endless possibilities
                  of the digital world.
                </div>
                <div className={style.bioContentText2}>
                  Join me on my journey as I discover and share the latest
                  trends and insights in the digital space.
                </div>
              </div>
            </div>
            <div className={style.kyMyPageContentUserInfoBalanceWrap}>
              <div className={style.kyMyPageContentUserInfoBalance}>
                Balance
              </div>
              <div className={style.kyMyPageContentUserInfoBalanceValue}>
                <div
                  className={
                    style.kyMyPageContentUserInfoBalanceValueValueItems
                  }
                >
                  <EthIcon />
                  <div>6.32 ETH</div>
                </div>
                <div
                  className={
                    style.kyMyPageContentUserInfoBalanceValueValueItems
                  }
                >
                  <PolyIcon />
                  <div>902.49 MATIC</div>
                </div>
                <div
                  className={
                    style.kyMyPageContentUserInfoBalanceValueValueItems
                  }
                >
                  <AvaxIcon />
                  <div>322.01 AVAX</div>
                </div>
              </div>
            </div>
            <div className={style.kyMyPageContentUserLocation}>
              <div className={style.kyMyPageContentUserLocationTitle}>
                Location
              </div>
              <div className={style.kyMyPageContentUserLocationValue}>
                <UsaIcon />
                <div>New York, USA</div>
              </div>
            </div>
            <div className={style.kyMyPageContentUserInfoSns}>
              <TwitterIcon />
              <FacebookIcon />
              <InstagramIcon />
              <AppleIcon />
              <GithubIcon />
            </div>
          </div>
          <div className={style.kyMyPageContentRight}>
            <div className={style.kyMyPageContentRightValue}>
              <div className={style.kyMyPageContentRightValueWrap}>
                <div className={style.kyMyPageContentRightValueTitle}>
                  TechSavvyGal’s Value
                </div>
                <div className={style.kyMyPageContentRightValueScoreContainer}>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover1}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>KY Value</div>
                        <ThunderIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        4,318,198
                      </div>
                    </div>
                  </div>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>Supporters</div>
                        <HeartIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        1128,245
                      </div>
                    </div>
                  </div>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>Supporting</div>
                        <StarIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        5,978153
                      </div>
                    </div>
                  </div>
                  <div className={style.kyMyPageContentRightValueScoreWrap}>
                    <div className={style.kyMyPageContentRightValueScoreCover}>
                      <div
                        className={style.kyMyPageContentRightValueScoreTitle}
                      >
                        <div>Points</div>
                        <BlinkIcon />
                      </div>
                      <div className={style.kyMyPageContentRightValueScore}>
                        102,328,198
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={process.env.PUBLIC_URL + "/image/collections.jpeg"} />
          </div>
        </div>
        <div className={style.kyMyPageContentBottom}>
          <img src={process.env.PUBLIC_URL + "/image/feedSection.jpeg"} />
        </div>
      </div>
    </section>
  );
};

export default FriendPage;
