import { ReactComponent as Search } from "./SearchIcon.svg";

const SearchIcon = () => {
  return (
    <>
      <Search />
    </>
  );
};

export default SearchIcon;
