import { ReactComponent as MobileTranslate } from "./mobileTranslate.svg";



const MobileTranslateIcon = () => {
    return (
        <div>
            <MobileTranslate />
        </div>
    );
};

export default MobileTranslateIcon;
