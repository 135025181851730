import { ReactComponent as UpArrow } from "./UpArrow.svg";

const UpArrowIcon = () => {
  return (
    <>
      <UpArrow />
    </>
  );
};

export default UpArrowIcon;
