export const userConnectedData = [
  {
    rank: "#1",
    profileImage: "/image/planetAvatar2.jpeg",
    id: "@digitalexplorer.eth",
    percent: "12.0%",
  },
  {
    rank: "#2",
    profileImage: "/image/planetAvatar3.jpeg",
    id: "@cryptovv",
    percent: "9.8%",
  },
  {
    rank: "#3",
    profileImage: "/image/planetAvatar4.jpeg",
    id: "@hihi1245",
    percent: "5.3%",
  },
  {
    rank: "#4",
    profileImage: "/image/planetAvatar5.jpeg",
    id: "@jid235",
    percent: "4.8%",
  },
  {
    rank: "#5",
    profileImage: "/image/planetAvatar6.jpeg",
    id: "@w13352w",
    percent: "3.7%",
  },
  {
    rank: "#6",
    profileImage: "/image/planetAvatar7.jpeg",
    id: "@wwonderlust",
    percent: "3.7%",
  },
  {
    rank: "#7",
    profileImage: "/image/planetAvatar8.jpeg",
    id: "@0x1523",
    percent: "3.1%",
  },
  {
    rank: "#8",
    profileImage: "/image/planetAvatar9.jpeg",
    id: "@58192425",
    percent: "2.4%",
  },
  {
    rank: "#9",
    profileImage: "/image/planetAvatar10.jpeg",
    id: "@k_aj2351",
    percent: "1.7%",
  },
];

export const userSavvyConnectedData = [
  {
    rank: "#1",
    profileImage: "/image/planetAvatar.jpeg",
    id: "@bitblur.eth",
    percent: "12.7%",
  },
  {
    rank: "#2",
    profileImage: "/image/planetAvatar3.jpeg",
    id: "@cryptovv",
    percent: "9.8%",
  },
  {
    rank: "#3",
    profileImage: "/image/planetAvatar7.jpeg",
    id: "@wwonderlust",
    percent: "3.7%",
  },
  {
    rank: "#4",
    profileImage: "/image/planetAvatar4.jpeg",
    id: "@hihi1245",
    percent: "5.3%",
  },
  {
    rank: "#5",
    profileImage: "/image/planetAvatar8.jpeg",
    id: "@0x1523",
    percent: "3.1%",
  },
  {
    rank: "#6",
    profileImage: "/image/planetAvatar9.jpeg",
    id: "@58192425",
    percent: "32.4%",
  },
  {
    rank: "#7",
    profileImage: "/image/planetAvatar6.jpeg",
    id: "@w13352w",
    percent: "3.7%",
  },
  {
    rank: "#8",
    profileImage: "/image/planetAvatar10.jpeg",
    id: "@k_aj2351",
    percent: "1.7%",
  },
  {
    rank: "#9",
    profileImage: "/image/planetAvatar5.jpeg",
    id: "@jid235",
    percent: "4.8%",
  },
];
