import "./style.scss";
import TranslateIcon from "../../../icon/translate/TranslateIcon";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

export type WebHeaderProps = {
  isMenuState: boolean;
  isMenuHandler: (event: React.MouseEvent<HTMLDivElement>) => void;
  languageChangeToEn: () => void;
  languageChangeToKo: () => void;
  isSelected: string;
  setIsSelected: (value: string) => void;
  isKoSelected: string;
  isEnSelected: string;
  setIsLoginModalOpen?: Dispatch<SetStateAction<boolean>>;
};

const WebHeader = (props: WebHeaderProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  return (
    <div className="headerContainer">
      <div className="headerLogoNav">
        <div className="headerLogo">
          <img
            src={process.env.PUBLIC_URL + "./image/KY_Logo.jpeg"}
            onClick={() => {
              props.setIsSelected("");
            }}
            alt="KYlogo"
          />
        </div>
        <nav className="headerItems">
          <div
            className={`headerItem ${
              props.isSelected === "ky" ? "kySelected" : "not"
            }`}
            onClick={() => {
              props.setIsSelected("ky");
            }}
          >
            Know Yourself
          </div>
          <div
            className={`headerItem ${
              props.isSelected === "by" ? "bySelected" : "not"
            }`}
            onClick={() => {
              props.setIsSelected("by");
            }}
          >
            Be Yourself
          </div>
          <div
            className="headerItem not"
            onClick={() => {
              if (isLoggedIn) {
                window.location.href = "/experience";
              } else {
                window.location.href = "/bitbluelock";
              }
            }}
          >
            Experience
          </div>
        </nav>
      </div>
      <div className="headerLanguageChange">
        <div
          className="languageChangeWrap"
          onClick={(event) => {
            props.isMenuHandler(event);
          }}
        >
          <div className="languageTranslate">KOR/ENG</div>
          <TranslateIcon />
        </div>
        {props.isMenuState && (
          <div className={`languageMenu ${props.isMenuState ? "active" : ""}`}>
            <div
              className={props.isEnSelected}
              onClick={() => props.languageChangeToEn()}
            >
              ENG
            </div>
            <div
              className={props.isKoSelected}
              onClick={() => props.languageChangeToKo()}
            >
              KOR
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebHeader;
