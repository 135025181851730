import { ReactComponent as ProfileDot } from "./ProfileDot.svg";

const ProfileDotIcon = () => {
  return (
    <>
      <ProfileDot />
    </>
  );
};

export default ProfileDotIcon;
