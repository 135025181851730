import style from "./style.module.scss";
import { StepProps } from "../step1/Step1";
import SmallColorPlanet from "../../../../../icon/smallColorPlanet/SmallColorPlanet";
import Finger from "../../../../../icon/finger/Finger";
const Step3 = (props: StepProps) => {
  return (
    <article className={style.step3ArticleWrap}>
      <div className={style.step3PlanetContainer}>
        <div className={style.step3Header}>Your Planet</div>
        <div className={style.step3PlanetComponent}>
          <SmallColorPlanet />
          <div className={style.step3User}>
            <div className={style.userInfo}>
              <div className={style.userName}>bitBlur</div>
              <div className={style.userId}>#13728121</div>
            </div>
            <div className={style.userPaymentInfo}>
              <div className={style.paymentInfoWrap}>
                <div className={style.infoItem1}>Tx Hash</div>
                <div className={style.infoItem2}>9C765C...3C5EDC</div>
              </div>
              <div className={style.paymentInfoWrap2}>
                <div className={style.infoItem1}>Tx Fee</div>
                <div className={style.infoItem2}>0 MATIC</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.step3Buttons}>
        <button
          className={style.myBtn}
          onClick={() => (window.location.href = "/experience/mypage")}
        >
          Go to My Page
        </button>
        <div className={style.saleFinger}>
          <button className={style.saleBtn} onClick={() => props.setIsStep(4)}>
            Sale My Planet
          </button>
          <div className={style.fingerWrap}>
            <Finger transform="rotate(0deg)" />
          </div>
        </div>
      </div>
    </article>
  );
};

export default Step3;
