import { ReactComponent as Eth } from "./Eth.svg";

const EthIcon = () => {
  return (
    <>
      <Eth />
    </>
  );
};

export default EthIcon;
