import KyHeader from "../kyHeader/KyHeader";
import style from "./style.module.scss";
import UpArrowIcon from "../../../icon/upArrow/UpArrow";
import CheckedIcon from "../../../icon/checked/Checked";
import NotCheckedIcon from "../../../icon/notCehcked/NotChecked";
import { DemoProps } from "../../../lib/types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

const KyTrends = (props: DemoProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/bitbluelock");
    return null; // 렌더링하지 않음
  }
  return (
    <section className={style.kyTrendsSection}>
      <KyHeader prefix={props.prefix} isPoint="world" />
      <div className={style.kyTrendsContentWrap}>
        <div className={style.kyTrendsGraphWrap}>
          <div className={style.kyTrendsGraphTitle}>Trend</div>
          <div className={style.kyTrendsGraphCategories}>
            <div className={style.kyTrendsGraphCategoryMenu}>
              <div className={style.kyTrendsGraphCategoryMenuItems1}>
                Collections
              </div>
              <div className={style.kyTrendsGraphCategoryMenuItems}>
                Astroids
              </div>
              <div className={style.kyTrendsGraphCategoryMenuItems}>
                Collectors
              </div>
            </div>
            <div className={style.kyTrendsGraphCategoryDay}>
              <div className={style.kyTrendsGraphCategoryDayItems1}>24h</div>
              <div className={style.kyTrendsGraphCategoryDayItems}>7d</div>
              <div className={style.kyTrendsGraphCategoryDayItems}>30d</div>
              <div className={style.kyTrendsGraphCategoryDayItems}>All</div>
            </div>
          </div>
          <div className={style.kyTrendsGraphDetails}>
            <div className={style.kyTrendsGraphDetailsWrap}>
              <div className={style.kyTrendsGraphDetailsItem}>
                <div className={style.kyTrendsGraphDetailsItemTitle}>
                  Volume
                </div>
                <div className={style.kyTrendsGraphDetailsItemValue}>
                  $800.78
                </div>
              </div>
              <div className={style.kyTrendsGraphDetailsItem2}>
                <div className={style.kyTrendsGraphDetailsItemTitle}>
                  NFT Holders
                </div>
                <div className={style.kyTrendsGraphDetailsItemsWrap}>
                  <div className={style.kyTrendsGraphDetailsItemValue}>
                    7,891
                  </div>
                  <div className={style.kyTrendsGraphDetailsItempercent}>
                    <UpArrowIcon />
                    24%
                  </div>
                </div>
              </div>
              <div className={style.kyTrendsGraphDetailsItem2}>
                <div className={style.kyTrendsGraphDetailsItemTitle}>
                  People reached
                </div>
                <div className={style.kyTrendsGraphDetailsItemsWrap}>
                  <div className={style.kyTrendsGraphDetailsItemValue}>
                    237,364
                  </div>
                  <div className={style.kyTrendsGraphDetailsItempercent}>
                    <UpArrowIcon />
                    24%
                  </div>
                </div>
              </div>
              <div className={style.kyTrendsGraphDetailsItem2}>
                <div className={style.kyTrendsGraphDetailsItemTitle}>
                  Link clicks
                </div>
                <div className={style.kyTrendsGraphDetailsItemsWrap}>
                  <div className={style.kyTrendsGraphDetailsItemValue}>679</div>
                  <div className={style.kyTrendsGraphDetailsItempercent}>
                    <UpArrowIcon />
                    6%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.kyTrendsStatsWrap}>
          <img
            src={process.env.PUBLIC_URL + "/image/trendsGraph.jpeg"}
            alt="graph"
          />
          <div className={style.kyTrendsStatsIcons}>
            <div className={style.kyTrendsStatsIconWrap}>
              <div className={style.kyTrendsStatsIcon}>
                <CheckedIcon />
                #100 NFT Collection
              </div>
            </div>
            <div className={style.kyTrendsStatsIconWrap}>
              <div className={style.kyTrendsStatsIcon}>
                <NotCheckedIcon />
                Total Collection
              </div>
            </div>
          </div>
        </div>
        <div className={style.kyTrendsTable}>
          <div className={style.kyTrendsTableWrap}>
            <div className={style.kyTrendsTableTitle}>Collection Stats</div>
            <div className={style.kyTrendsTableContent}>
              <img
                src={process.env.PUBLIC_URL + "/image/categories.jpeg"}
                alt="table"
              />
              <img
                src={process.env.PUBLIC_URL + "/image/table.jpeg"}
                alt="table"
              />
              <img
                src={process.env.PUBLIC_URL + "/image/pagination.jpeg"}
                alt="table"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KyTrends;
