import { ReactComponent as Facebook } from "./Facebook.svg";

const FacebookIcon = () => {
  return (
    <>
      <Facebook />
    </>
  );
};

export default FacebookIcon;
