import { ReactComponent as SmallColorPlanet } from "./SmallColorPlanet.svg";

const SmallColorPlanetIcon = () => {
  return (
    <>
      <SmallColorPlanet />
    </>
  );
};

export default SmallColorPlanetIcon;
