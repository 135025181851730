import { ReactComponent as NotChecked } from "./NotChecked.svg";

const NotCheckedIcon = () => {
  return (
    <>
      <NotChecked />
    </>
  );
};

export default NotCheckedIcon;
