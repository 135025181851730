import { ReactComponent as Instagram } from "./Instagram.svg";

const InstagramIcon = () => {
  return (
    <>
      <Instagram />
    </>
  );
};

export default Instagram;
