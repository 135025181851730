import { ReactComponent as SpacePlanet6 } from "./spacePlanet6.svg";

const Planet6 = () => {
  return (
    <>
      <SpacePlanet6 />
    </>
  );
};

export default Planet6;
