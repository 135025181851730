import { ReactComponent as Profile } from "./Profile.svg";

const ProfileIcon = () => {
  return (
    <>
      <Profile />
    </>
  );
};

export default ProfileIcon;
