import style from "./style.module.scss";

interface AvatarProps {
  title: string;
  name: string;
  image: string;
}
const Avatar = (props: AvatarProps) => {
  return (
    <div className={style.avatarWrap}>
      <div className={style.avatarImage}>
        <img
          src={process.env.PUBLIC_URL + "/image/" + `${props.image}.jpeg`}
          alt=""
        />
      </div>
      <div className={style.avatarTextWrap}>
        <div className={style.avatarTitle}>
          <div>{props.title}</div>
        </div>
        <div className={style.avatarName}>
          <div>{props.name}</div>
        </div>
      </div>
    </div>
  );
};

export default Avatar;
