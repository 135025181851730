import style from "./style.module.scss";
import { ReactNode, useState } from "react";

interface ModalProps {
  children: ReactNode;
  isModalOpen?: boolean;
}

const Modal = (props: ModalProps) => {
  return (
    <div className={props.isModalOpen ? style.modal : style.modalDisabled}>
      <div className={style.modalContent}>{props.children}</div>
    </div>
  );
};

export default Modal;
