import TranslateIcon from "../../icon/translate/TranslateIcon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useCurrentLocale } from "../../lib/function";
import { useDispatch } from "react-redux";
import { setCurrentLocale } from "../../store";

import "./style.scss";
import WebHeader from "./WebHeader/WebHeader";
import MobileHeader from "./MobileHeader/MobileHeader";

interface HeaderProps {
  isSelected: string;
  setIsSelected: (value: string) => void;
  prefix: string | undefined;
  isModalOpen?: boolean;
  setIsLoginModalOpen?: Dispatch<SetStateAction<boolean>>;
}

const Header = (props: HeaderProps) => {
  const [isMenuState, setIsMenuState] = useState(false);
  const currentLocale = useCurrentLocale();
  const dispatch = useDispatch();
  const isEnSelected =
    currentLocale === "en" ? "isSelectedItem" : "isNotSelected";

  const isKoSelected =
    currentLocale === "ko" ? "isKorSelectedItem" : "isKorNotSelectedItem";

  const isMenuHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsMenuState((prev) => !prev);
    event.stopPropagation();
  };

  const languageChangeToEn = () => {
    dispatch(setCurrentLocale("en"));
  };

  const languageChangeToKo = () => {
    dispatch(setCurrentLocale("ko"));
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      setTimeout(() => {
        setIsMenuState(false);
      }, 700);
    };
    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  const prefixRenderer = () => {
    if (props.prefix) {
      switch (props.prefix) {
        case "xxl":
        case "xl":
          return (
            <WebHeader
              isMenuState={isMenuState}
              isMenuHandler={isMenuHandler}
              languageChangeToEn={languageChangeToEn}
              languageChangeToKo={languageChangeToKo}
              isSelected={props.isSelected}
              setIsSelected={props.setIsSelected}
              isEnSelected={isEnSelected}
              isKoSelected={isKoSelected}
              setIsLoginModalOpen={props.setIsLoginModalOpen}
            />
          );
        case "md":
        case "sm":
          return (
            <MobileHeader
              isMenuState={isMenuState}
              isMenuHandler={isMenuHandler}
              languageChangeToEn={languageChangeToEn}
              languageChangeToKo={languageChangeToKo}
              isSelected={props.isSelected}
              setIsSelected={props.setIsSelected}
              isEnSelected={isEnSelected}
              isKoSelected={isKoSelected}
              setIsLoginModalOpen={props.setIsLoginModalOpen}
            />
          );
        default:
          return (
            <WebHeader
              isMenuState={isMenuState}
              isMenuHandler={isMenuHandler}
              languageChangeToEn={languageChangeToEn}
              languageChangeToKo={languageChangeToKo}
              isSelected={props.isSelected}
              setIsSelected={props.setIsSelected}
              isEnSelected={isEnSelected}
              isKoSelected={isKoSelected}
              setIsLoginModalOpen={props.setIsLoginModalOpen}
            />
          );
      }
    }
  };

  return <header className="headerWrap">{prefixRenderer()}</header>;
};

export default Header;
