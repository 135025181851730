import style from "./style.module.scss";
import KyLogo from "../../../icon/kyLogo/KyLogo";
import SearchIcon from "../../../icon/searchIcon/SearchIcon";
import MessageIcon from "../../../icon/messageIcon/Message";
import BellIcon from "../../../icon/bell/BellIcon";
import ProfileIcon from "../../../icon/profile/ProfileIcon";
import DownArrowIcon from "../../../icon/downArrow/DownArrowIcon";
import React from "react";
import { Link } from "react-router-dom";
import ProfileDropDeown from "../../../component/profileDropDown/ProfileDropDown";
import { DemoProps } from "../../../lib/types";
import Finger from "../../../icon/finger/Finger";
const KyHeader = (props: DemoProps) => {
  const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
  return (
    <header className={style.headerWrap}>
      <article className={style.headerArticle}>
        <div className={style.headerIcons}>
          <div
            className={
              props.isPoint === "logo"
                ? style.headerPointer
                : style.headerPointerNot
            }
          >
            <div
              onClick={() => {
                window.location.href = "/experience";
              }}
              className={style.headerIconLogo}
            >
              <KyLogo />
            </div>

            {props.isPoint === "logo" && (
              <div className={style.headerFinger1}>
                <Finger />
              </div>
            )}
          </div>
          {props.prefix === "md" ? <></> : <SearchIcon />}
        </div>
        <div className={style.headerItems}>
          <div className={style.headerTextItems}>
            <div
              className={
                props.isPoint === "market"
                  ? style.headerPointer
                  : style.headerPointerNot
              }
            >
              <Link to="/experience/marketplace">
                <div className={style.headerItem}>Marketplace</div>
              </Link>
              {props.isPoint === "market" && (
                <div className={style.headerFinger2}>
                  <Finger />
                </div>
              )}
            </div>
            <div className={style.headerItem}>Social</div>
            <div
              className={
                props.isPoint === "trends"
                  ? style.headerPointer
                  : style.headerPointerNot
              }
            >
              <Link to="/experience/trends">
                <div className={style.headerItem}>Trends</div>
              </Link>
              {props.isPoint === "trends" && (
                <div className={style.headerFinger3}>
                  <Finger />
                </div>
              )}
            </div>
            <div
              className={
                props.isPoint === "world"
                  ? style.headerPointer
                  : style.headerPointerNot
              }
            >
              <Link to="/experience/world">
                <div className={style.headerItem}>World</div>
              </Link>
              {props.isPoint === "world" && (
                <div className={style.headerFinger4}>
                  <Finger />
                </div>
              )}
            </div>
            <div className={style.headerItem}>Stage</div>
            <div className={style.headerItem}>Agora</div>
          </div>
          <div className={style.headerItemIcons}>
            <Link to="/experience/chat">
              <MessageIcon />
            </Link>
            <BellIcon />
          </div>
          <div
            className={style.headerProfile}
            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
          >
            <ProfileIcon />
            <DownArrowIcon />
          </div>
        </div>
      </article>
      {isDropDownOpen && <ProfileDropDeown isOpen={isDropDownOpen} />}
    </header>
  );
};

export default KyHeader;
