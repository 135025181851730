import { ReactComponent as ChatRoom } from "./ChatRoomIcon.svg";

const ChatRoomIcon = () => {
    return (
        <>
            <ChatRoom />
        </>
    );
};

export default ChatRoomIcon;
